import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import chartIcon from '../assets/icons/chart.png'; // 📊 Analyze
import crossIcon from '../assets/icons/cross.png'; // ❌ Incomplete
import editIcon from '../assets/icons/edit.png'; // ✏️ Edit
import searchIcon from '../assets/icons/search.png'; // 🔍 Search
import tickIcon from '../assets/icons/tick.png'; // ✅ Completed
import waitIcon from '../assets/icons/wait.png'; // ⏳ In progress
import { ALL_COMPANY_TEAM } from '../configs/config';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';

const userApiPrefix = '/api/v1/org/user/dash';
const statusIcons = [crossIcon, waitIcon, tickIcon];
const OrgDashboard = () => {
    const navigate = useNavigate();
    const [teams, setTeams] = useState([]);
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        getStatus();
    }, [navigate]);

    function editTeam(hash) {
        navigate(`/org/teams?th=${hash}`);
    }

    function teamProf(hash) {
        navigate(`/org/teamprof?th=${hash}`);
    }

    function editJob(hash, teamHash) {
        navigate(`/org/jobs?jh=${hash}&th=${teamHash}`);
    }

    function searchCandidates(hash, teamHash, jobName, teamName) {
        const args = {
            job_hash: hash,
            team_hash: teamHash,
            job_name: encodeURIComponent(jobName),
            team_name: encodeURIComponent(teamName),
        }
        const argStr = Object.keys(args).map((key) => key + '=' + args[key]).join('&');
        navigate('/org/copilot?' + argStr)
    }

    function displayTeams() {
        return (
            <div>
                <h2>Teams</h2>
                {teams.length === 0 ?
                    <Link to='/org/teams'><button className='button-cta'>Add a Team</button></Link>
                    :
                    <table className='table-with-border-oss'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Profile Status</th>
                                <th># Jobs</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {teams.map((team) => (
                                <tr key={team.hash}>
                                    <td className='no_margin_h2_clickable' onClick={() => teamProf(team.hash)}>{team.name}</td>
                                    <td><img src={(team.status >= 0 && team.status < statusIcons.length) ? statusIcons[team.status] : statusIcons[0]} className='icon' /></td>
                                    <td>{team.jobs}</td>
                                    <td>
                                        <div className='two_columns'>
                                            <img src={editIcon} className='icon-btn' onClick={() => editTeam(team.hash)} />
                                            {team.status === 2 && <img src={chartIcon} className='icon-btn' onClick={() => teamProf(team.hash)} />}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        )
    }

    function displayJobs() {
        if (teams.length === 0) {
            return null;
        }
        return (
            <div>
                <h2>Jobs</h2>
                {jobs.length === 0 ?
                    <Link to='/org/jobs'><button className='button-cta'>Add a Job</button></Link>
                    :
                    <table className='table-with-border-oss'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Team</th>
                                <th># Candidates</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.map((job) => (
                                <tr key={job.hash}>
                                    <td className='no_margin_h2_clickable' onClick={() => searchCandidates(job.hash, job.team_hash, job.name, job.team_name)}>{job.name}</td>
                                    <td>{job.team_name}</td>
                                    <td>{job.candidates}</td>
                                    <td>
                                        <div className='two_columns'>
                                            <img src={editIcon} className='icon-btn' onClick={() => editJob(job.hash, job.team_hash)} />
                                            {job.candidates > 0 && <img src={searchIcon} className='icon-btn' onClick={() => searchCandidates(job.hash, job.team_hash, job.name, job.team_name)} />}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        )
    }

    async function getStatus() {
        const response = await fetchAPIWithToken(navigate, `${userApiPrefix}`, 'GET', {});
        if (response.status === 200) {
            const data = await response.json();
            setJobs(data.jobs);
            // filter ALL_COMPANY_TEAM
            data.teams = data.teams.filter((team) => team.name !== ALL_COMPANY_TEAM);
            setTeams(data.teams);
        }
    }
    return (
        <div className="flex-container">
            <div>
                {displayTeams()}
            </div>
            <div>
                {displayJobs()}
            </div>
        </div>
    );
};

export default OrgDashboard;
