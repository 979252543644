import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { selectStyles } from './SelectStyles';

const MultiSelect = ({ items, maxSel, onSelectChange, itemKey = null, defItems = [] }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [itemsChanged, setItemsChanged] = useState(true);

    // Set options when items or itemKey change
    useEffect(() => {
        if (!items) {
            setSelectedItems([]);
            return;
        }

        setOptions(items.map((item, index) => ({
            value: index,
            label: itemKey ? item[itemKey] : item,
        })));
        setSelectedItems([]);
        setItemsChanged(true);
    }, [items, itemKey]);

    // Set selected items if defItems changes
    useEffect(() => {
        if (!items || !itemsChanged) {
            return;
        }
        if (defItems || defItems.length > 0) {
            const defIndices = getSelectedIndices(defItems, items);
            setSelectedItems(defIndices.map((item) => ({
                value: item,
                label: itemKey ? items[item][itemKey] : items[item],
            })));
            setItemsChanged(false);
        }
    }, [defItems, items, itemsChanged]);

    function getSelectedIndices(selectedItems, items) {
        if (!selectedItems || selectedItems.length === 0 || !items || items.length === 0) {
            return [];
        }
        const k_ids = selectedItems.map(item => itemKey ? item[itemKey] : item);
        const indices = [];
        items.forEach((item, index) => {
            if (k_ids.includes(itemKey ? item[itemKey] : item)) {
                indices.push(index);
            }
        });
        return indices;
    }

    // Handle selection change
    const handleItemSelectChange = (selectedOptions) => {
        const selectedIndices = selectedOptions.map(option => option.value);

        if (selectedIndices.length <= maxSel) {
            setSelectedItems(selectedOptions);
            onSelectChange(selectedIndices);
        } else {
            alert(`Please select only up to ${maxSel} items`);
        }
    };

    return (
        <Select
            isMulti
            value={selectedItems}
            onChange={handleItemSelectChange}
            options={options}
            placeholder={`Select up to ${maxSel} values`}
            closeMenuOnSelect={true}
            styles={selectStyles}
        />
    );
};

export default MultiSelect;
