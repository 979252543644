import React, { useEffect, useState } from 'react';
import crossIcon from '../assets/icons/cross.png'; // ❌ Incomplete
import tickIcon from '../assets/icons/tick.png'; // ✅ Completed
import '../styles/ProgressTracker.css';



const ProgressTracker = ({ currPage, goto, status, steps, getIndexOf, showTrack = true }) => {

    const [allSet, setAllSet] = useState(false);

    useEffect(() => {
        setAllSet(isAllSet(status, steps));
    }, [status, steps]);

    function isSet(value, index) {
        if (value === 0) {
            return false;
        }
        const flag = 2 ** (index - 1);
        return (value & flag) !== 0;
    }

    function isAllSet(value, steps) {
        for (let i = 0; i < steps.length; i++) {
            if (!isSet(value, i + 1)) {
                return false;
            }
        }
        return true;
    }

    return (
        <div className="progress-tracker-left">
            {steps.map((step, index) => (
                <div key={index} className={`step ${isSet(status, index + 1) ? 'completed' : ''} ${currPage === step.path ? 'active' : ''}`}>
                    {!allSet && showTrack &&
                        <>
                            <div className="step-icon" onClick={() => goto(step.path)}>
                                {isSet(status, index + 1) ? (
                                    <img src={tickIcon} alt="✅" title="Completed" />
                                ) : (
                                    <img src={crossIcon} alt="❌" title="Incomplete" />
                                )}
                            </div>
                            {index !== steps.length - 1 && (
                                <div className="step-line"></div>
                            )}
                        </>
                    }
                    <button className={`step-label ${getIndexOf(currPage) === index ? 'active' : ''}`} onClick={() => goto(step.path)}>
                        {step.name}
                    </button>
                </div>
            ))}
        </div>
    );
};

export default ProgressTracker;
