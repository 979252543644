// config.js

const protocol = window.location.protocol;
const domain = window.location.hostname;
const port = window.location.port ? `:${window.location.port}` : '';

const prodServer = 'https://api.app.modelteam.ai'
const demoServer = 'https://api.app.shastraw.ai'
const devServer = 'http://127.0.0.1:5000'

const baseUrl = `${protocol}//${domain}${port}`;

const ALL_COMPANY_TEAM = '🤖 All Company'

let backendUrl = devServer;
const nodeEnv = process.env.NODE_ENV;
if (nodeEnv === 'production') {
    domain.includes('modelteam.ai') ? backendUrl = prodServer : backendUrl = demoServer;
}

const frontendUrl = baseUrl; // configuration for frontend servers, used for profile links

export { backendUrl, frontendUrl, ALL_COMPANY_TEAM };
