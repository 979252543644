import React, { useEffect, useRef } from 'react';
import { backFillQtrs } from '../utils/DateTimeUtils';
import { trimString } from '../utils/Utils';

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  BarController,
  PointElement,
  LineElement,
  LineController,
);

const BarChart = ({ dates, data, numYears, chartLabel, yMax, yLabel, baseLineDateList, baseLineDataList, baseLineChartlabels, codeQuality }) => {
  const backFillData = backFillQtrs(dates, data, numYears);
  let backFillBaseLineDataPointsList = [];
  let maxLegendLength = 30;
  if (baseLineDateList && baseLineDataList) {
    baseLineDateList.map((baselineDates, index) => {
      const backFillBaseLineData = backFillQtrs(baselineDates, baseLineDataList[index], numYears);
      const backFillBaseLineDataPoints = backFillBaseLineData.values.map((point, index) => ({
        x: backFillBaseLineData.quarters[index],
        y: point
      }));
      backFillBaseLineDataPointsList.push(backFillBaseLineDataPoints);
    });
  }

  const dataPoints = backFillData.values.map((point, index) => ({
    x: backFillData.quarters[index],
    y: point
  }));

  const barColor = getComputedStyle(document.documentElement).getPropertyValue('--bg-brand-primary').trim();
  const borderColor = getComputedStyle(document.documentElement).getPropertyValue('--bg-brand-secondary').trim();
  const borderRadiusStr = getComputedStyle(document.documentElement).getPropertyValue('--bar-chart-radius').trim();
  const match = borderRadiusStr.match(/([\d.]+)/);
  const borderRadius = match ? parseInt(match[1]) : 0;

  const chartData = {
    labels: backFillData.quarters,
    datasets: (data && data.length > 0) ? [
      {
        label: trimString(chartLabel, maxLegendLength),
        data: dataPoints,
        backgroundColor: barColor,
        borderColor: borderColor,
        borderRadius: borderRadius,
        borderWidth: 1,
        order: 10,
      },
    ] : []
  };
  if (codeQuality) {
    const backFillCQData = backFillQtrs(dates, codeQuality, numYears);
    const codeQualityPoints = backFillCQData.values.map((point, index) => ({
      x: backFillData.quarters[index],
      y: point
    }));
    chartData.datasets.push({
      label: 'Code Quality (Beta)',
      data: codeQualityPoints,
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 2,
      type: 'line',
      yAxisID: 'y2',
      order: 0,
    });
  }

  if (backFillBaseLineDataPointsList) {
    const colors = [
      'rgb(255, 99, 132)',   // Red
      'rgb(54, 162, 235)',   // Blue
      'rgb(255, 206, 86)',   // Yellow
      'rgb(75, 192, 192)',   // Teal
      'rgb(153, 102, 255)',  // Purple
      'rgb(255, 159, 64)',   // Orange
      'rgb(0, 204, 102)',    // Green
      'rgb(255, 140, 0)',    // Dark Orange
      'rgb(220, 20, 60)',    // Crimson
      'rgb(148, 0, 211)'     // Dark Violet
    ];
    backFillBaseLineDataPointsList.map((backFillBaseLineDataPoints, index) => {
      const color = colors[index % colors.length];
      chartData.datasets.push({
        label: trimString(baseLineChartlabels[index], maxLegendLength),
        data: backFillBaseLineDataPoints,
        backgroundColor: color,
        borderColor: color.replace(')', ', 0.8)'),
        borderWidth: 1,
        type: 'line',
        order: index + 3,
      });
    });
  }

  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) {
      console.error('chartRef.current is null');
      return;
    }

    const ctx = chartRef.current.ctx;
    const barColorTop = getComputedStyle(document.documentElement).getPropertyValue('--bar-chart-top').trim();
    const barColorMiddle = getComputedStyle(document.documentElement).getPropertyValue('--bar-chart-middle').trim();
    const barColorBottom = getComputedStyle(document.documentElement).getPropertyValue('--bar-chart-bottom').trim();
    const gradient = ctx.createLinearGradient(0, 0, 0, chartRef.current.height);
    gradient.addColorStop(0, barColorTop);
    gradient.addColorStop(0.47, barColorMiddle);
    gradient.addColorStop(0.96, barColorBottom);
    chartRef.current.data.datasets[0].backgroundColor = gradient;
    chartRef.current.update();
  }, [chartRef]);

  return (
    <Chart
      data={chartData}
      type='bar'
      options={{
        scales: {
          x: {
            display: true,
            color: 'rgba(255, 103, 70, 0.2)',
            grid: {
              display: false,
              color: 'rgba(255, 103, 70, 0.2)'
            },
            ticks: {
              color: '#f7f7f7', // Change to desired color (e.g., red)
            },
          },
          y: {
            display: true,
            grid: {
              display: false,
              color: 'rgba(255, 103, 70, 0.2)'
            },
            title: {
              display: true,
              text: yLabel,
              color: '#d7eae4',
              font: {
                size: 14,
              },
            },
            ticks: {
              color: '#f7f7f7', // Change to desired color (e.g., red)
            },
            min: 0,
            max: yMax ? yMax : null,
          },
          y2: {
            position: 'right',
            display: codeQuality ? true : false,
            title: {
              display: true,
              text: 'Code Quality (Beta)',
              color: '#36A2EB'
            },
            min: 0,
            max: 1,
            grid: {
              display: false,
            }
          }
        },
        plugins: {
          legend: {
            display: backFillBaseLineDataPointsList?.length > 0 ? true : false,
            position: 'bottom',
            labels: {
              color: 'white',
              font: {
                size: 12,
              },
              boxWidth: 12,
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      }}
      canvasprops={{ className: 'skill-bar-chart' }}
      ref={chartRef}
    />
  );
};

export default BarChart;
