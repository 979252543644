import { frontendUrl } from '../configs/config';

export function toTitleCase(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
export const arrToMap = (arr) => {
    const res = {};
    if (!arr) {
        return res;
    }
    for (let i = 0; i < arr.length; i++) {
        res[arr[i]['_key']] = arr[i];
    }
    return res;
};

export const getProfileUrl = (profileId) => {
    return `${frontendUrl}/profile?id=${profileId}`
}

export function trimString(str, length = 10) {
    if (str.length > length) {
        return str.substring(0, length - 3) + '...';
    }
    return str;
}

export function getLangWeightedScore(lang, quarters) {
    const sm = lang.date_vector.reduce((map, key, index) => {
        map[key] = lang.vector[index];
        return map;
    }, {});
    return generateWeightedScore(sm, quarters);
}

export function getSkillWeightedScore(skill, quarters) {
    return generateWeightedScore(skill.quarterly_stats, quarters);
}

export function generateQuarters(numQuarters = 8) {
    const currYear = new Date().getFullYear();
    const currQtr = Math.floor((new Date().getMonth()) / 3) + 1;
    let quarters = [`${currYear}Q${currQtr}`];
    let quarter = currQtr;
    let year = currYear;

    for (let i = 1; i < numQuarters; i++) {
        quarter -= 1;
        if (quarter === 0) {
            quarter = 4;
            year -= 1;
        }
        quarters.push(`${year}Q${quarter}`);
    }
    return quarters;
}

export function generateWeightedScore(inputScores, quarters) {
    // TODO: This should be same as the one in Backend ss_utils.py
    const weights = [0.33, 0.32, 0.15, 0.1, 0.05, 0.03, 0.015, 0.005]; // Sum ~1.0

    let weightedScore = quarters.reduce((sum, q, index) => {
        return sum + (inputScores[q] || 0) * weights[index];
    }, 0);

    return weightedScore;
}

export function generateSearchWeightedScore(inputScores, quarters) {
    const weights = [1, 1, 0.8, 0.8, 0.6, 0.6, 0.4, 0.4];

    let weightedScore = quarters.reduce((sum, q, index) => {
        return sum + (inputScores[q] || 0) * weights[index];
    }, 0);

    return weightedScore;
}

