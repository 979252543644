// src/Jobs.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileTab from '../components/ProfileTab';
import UserCohortSelect from '../components/UserCohortSelect';
import '../styles/jobs.css';
import '../styles/profile.css';
import '../styles/root.css';
import '../styles/search.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { ORG_PREFIX } from '../utils/CheckToken';

const teamPrefix = '/api/v1/org/team';
const teamsApiPrefix = teamPrefix + '/teams?minstatus=2';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}


// Cache for profiles. Key will be team_hash + user_hash
const profilesCache = {};

const OrgTeamProf = () => {
    const navigate = useNavigate();
    const [orgAccountUserEmailHash, setOrgAccountUserEmailHash] = useState(localStorage.getItem(ORG_PREFIX + 'email_hash'));
    const [selectedTeamIndex, setSelectedTeamIndex] = useState(-1);
    const [teams, setTeams] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const query = useQuery();
    const [currTeamHash, setCurrTeamHash] = useState(query.get('th') || null);
    const [selectedTeamMemberIndex, setSelectedTeamMemberIndex] = useState('c-0-');
    const [currUserHash, setCurrUserHash] = useState(null);
    const [companyProfile, setCompanyProfile] = useState(null);
    const [l1, setL1] = useState(null);
    const [l2, setL2] = useState(null);
    const [l3, setL3] = useState(null);
    const [baselines, setBaselines] = useState(null);
    const [baselineAccountInfos, setBaselineAccountInfos] = useState(null);
    const [includeCompany, setIncludeCompany] = useState(false);

    const handleCheckboxChange = () => {
        setIncludeCompany(!includeCompany);
    };


    useEffect(() => {
        if (l2 && l3) {
            setBaselines([l2['display_profile'], l3['display_profile']]);
            setBaselineAccountInfos([l2['account_info'], l3['account_info']]);
        } else if (l2) {
            setBaselines([l2['display_profile']]);
            setBaselineAccountInfos([l2['account_info']]);
        } else {
            setBaselines(null);
            setBaselineAccountInfos(null);
        }
    }, [l2, l3]);

    useEffect(() => {
        loadTeams();
        loadCompanyProfile();
    }, [orgAccountUserEmailHash]);

    useEffect(() => {
        if (!currUserHash || !currUserHash.display_profile) {
            return;
        }
        const langs = {};
        const skills = {};
        currUserHash.display_profile.langs.forEach((item, index) => {
            const key = item['_key'];
            if (index < 10) {
                langs[key] = 1
            }
            else {
                langs[key] = 0
            }
        });
        currUserHash.display_profile.skills.forEach((item, index) => {
            const key = item['_key'];
            if (index < 10) {
                skills[key] = 1
            }
            else {
                skills[key] = 0
            }
        });
    }, [currUserHash]);

    useEffect(() => {
        if (!currTeamHash) {
            setTeamMembers([]);
            setCurrUserHash(null);
            return;
        }
        loadTeamMembers(currTeamHash)
    }, [currTeamHash]);

    useEffect(() => {
        updateProfile();
    }, [currTeamHash, currUserHash, includeCompany, companyProfile, selectedTeamIndex]);

    const loadCompanyProfile = async () => {
        if (!orgAccountUserEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${teamPrefix}/company`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data) {
            setCompanyProfile(data);
            if (!currTeamHash) {
                setCurrTeamHash(data.team_hash);
            }
        }
    };

    const loadTeamMembers = async (teamHash) => {
        if (!teamHash) {
            return;
        }
        // Get team members and cohorts with status >= 2
        const response = await fetchAPIWithToken(navigate, `${teamPrefix}/members?team_hash=${teamHash}&minstatus=2`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        const members = data.users
        const cohorts = data.cohorts
        if (members.length > 0) {
            setTeamMembers(members);
            if (cohorts.length === 0) {
                setSelectedTeamMemberIndex('u-0-');
                setCurrUserHash(members[0].user_hash);
            }
        } else {
            setTeamMembers([]);
        }
        if (cohorts.length > 0) {
            setCohorts(cohorts);
            // Cohort will be selected if there are no team members
            setSelectedTeamMemberIndex('c-0-');
            setCurrUserHash(cohorts[0].user_hash);
        } else {
            setCohorts([]);
        }
        if (members.length === 0 && cohorts.length === 0) {
            setCurrUserHash(null);
        }
    };

    const loadTeams = async () => {
        if (!orgAccountUserEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${teamsApiPrefix}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            setTeams(data);
            if (currTeamHash) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].team_hash === currTeamHash) {
                        setSelectedTeamIndex(i);
                        setCurrTeamHash(data[i].team_hash);
                        return;
                    }
                }
            }
            if (selectedTeamIndex === -1) {
                // pick a random team
                const randTeamIndex = Math.floor(Math.random() * data.length);
                setSelectedTeamIndex(randTeamIndex);
                setCurrTeamHash(data[randTeamIndex].team_hash);
            }
        } else {
            setTeams([]);
            setCurrTeamHash(null);
        }
    };

    const handleTeamSelectChange = (event) => {
        const index = event.target.value;
        if (!index || index === -1) {
            return;
        }
        setSelectedTeamIndex(index);
        setCurrTeamHash(teams[index].team_hash);
    };

    const handleTeamMemberSelectChange = (selectedValue) => {
        setSelectedTeamMemberIndex(selectedValue);
        const index = selectedValue.split('-')[1];
        if (selectedValue.startsWith('u-')) {
            setCurrUserHash(teamMembers[index].user_hash);
        } else if (selectedValue.startsWith('c-')) {
            setCurrUserHash(cohorts[index].user_hash);
        }
    };

    const updateProfile = async () => {
        if (!currTeamHash && !currUserHash) {
            return;
        }
        if (selectedTeamIndex === -1) {
            return;
        }
        let tp = null;
        let up = null;
        const companyUserKey = companyProfile ? companyProfile.team_hash + companyProfile.user_hash : "NoCompanyProfile";
        let userKey = null;
        if (currTeamHash) {
            if (currUserHash) {
                userKey = currTeamHash + currUserHash;
                if (!profilesCache[userKey]) {
                    const response = await fetchAPIWithToken(navigate, `${teamPrefix}/member?team_hash=${currTeamHash}&user_hash=${currUserHash}`, 'GET', {});
                    if (response.status !== 200) {
                        console.error(response);
                        return;
                    }
                    const data = await response.json();
                    profilesCache[userKey] = data;
                }
                if (userKey !== companyUserKey) {
                    up = profilesCache[userKey];
                }
            }
            if (!profilesCache[currTeamHash]) {
                const response = await fetchAPIWithToken(navigate, `${teamPrefix}/teamavg?team_hash=${currTeamHash}&team_name=${teams[selectedTeamIndex].team_name}`, 'GET', {});
                if (response.status !== 200) {
                    console.error(response);
                    return;
                }
                const data = await response.json();
                profilesCache[currTeamHash] = data;
            }
            const teamUserKey = currTeamHash + profilesCache[currTeamHash].user_hash;
            if (teamUserKey !== companyUserKey && teamUserKey !== userKey) {
                tp = profilesCache[currTeamHash];
            }
        }
        let l1 = null;
        let l2 = null;
        let l3 = null;
        if (up) {
            l1 = up;
            l2 = tp ? tp : companyProfile;
            l3 = (includeCompany && tp) ? companyProfile : null;
        } else if (tp) {
            l1 = tp;
            l2 = companyProfile;
        } else {
            l1 = companyProfile;
        }
        setL1(l1);
        setL2(l2);
        setL3(l3);
    };

    return ((teams.length === 0 || !companyProfile) ?
        <h2>Team profile is needed to analyze the teams
            <br />Follow the instructions in our <a href='https://github.com/modelteam-ai/modelteam.ai/blob/main/README_org.md#getting-started' target='_blank' rel="noreferrer">Git README</a> to build one.</h2>
        :
        <div className='team-prof'>
            <div className='team-prof-header'>
                <div>
                    <label>Company/Team*</label>
                    <select onChange={handleTeamSelectChange} value={selectedTeamIndex}>
                        <option value="">Select a Team</option>
                        {teams.map((item, index) => (
                            <option key={index} value={index}>
                                {item.team_name}
                            </option>
                        ))}
                    </select>
                </div>
                <UserCohortSelect teamMembers={teamMembers} cohorts={cohorts} handleTeamMemberSelectChange={handleTeamMemberSelectChange} suffix={''} defVal={selectedTeamMemberIndex} />
                <label>
                    <input
                        type="checkbox"
                        checked={includeCompany}
                        onChange={handleCheckboxChange}
                    />
                    Include Company Baseline
                </label>
            </div>
            {l1 && <ProfileTab profileData={l1['display_profile']} accountInfo={l1['account_info']} baselines={baselines} baselineAccountInfos={baselineAccountInfos} />}
        </div>
    );
};

export default OrgTeamProf;
