import React, { useEffect, useState } from "react";
import '../styles/root.css';

const OrgACL = ({ teams, saveUser, cancelEdit, currUser = null, isEditing }) => {
    const [userName, setUserName] = useState(currUser ? currUser.user_name : "");
    const [userEmail, setUserEmail] = useState(currUser ? currUser.user_email : "");
    const [isOrgAdmin, setIsOrgAdmin] = useState(currUser ? currUser.is_org_admin : true);
    const [availableTeams, setAvailableTeams] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedAvailableTeams, setSelectedAvailableTeams] = useState([]);
    const [selectedSelectedTeams, setSelectedSelectedTeams] = useState([]);

    useEffect(() => {
        if (isEditing && currUser && currUser.teams) {
            setAvailableTeams(teams.filter(team => !currUser.teams.includes(team.team_hash)));
            setSelectedTeams(teams.filter(team => currUser.teams.includes(team.team_hash)));
        } else {
            setAvailableTeams(teams);
        }
    }, [teams, currUser, isEditing]);

    const moveTeams = (from, to, setFrom, setTo, selected) => {
        const selectedHashes = new Set(selected); // Get selected team_hashes
        setFrom(from.filter(t => !selectedHashes.has(t.team_hash))); // Remove from "from" list
        setTo([...to, ...from.filter(t => selectedHashes.has(t.team_hash))]); // Add to "to" list
    };

    const validateInput = () => {
        return userName && userEmail;
    }

    const handleSubmit = () => {
        if (!validateInput()) {
            alert("Please fill in all fields");
            return;
        }
        const userData = {
            user_name: userName,
            user_email: userEmail,
            is_org_admin: isOrgAdmin,
            teams: selectedTeams.map(team => team.team_hash), // Send only team hashes
        };
        if (currUser) {
            userData['user_email_hash'] = currUser.user_email_hash;
        }
        saveUser(userData);
    };

    return (
        <div className="whoami">
            <div className="two_columns">
                <label>
                    Name* (Can't be changed):
                    <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} readOnly={isEditing} />
                </label>
                <label>
                    Email* (Can't be changed):
                    <input type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value.toLowerCase().trim())} readOnly={isEditing} />
                </label>
            </div>
            {isEditing ? <label>Role: <b>{isOrgAdmin ? "Admin" : "Team Manager"}</b></label> :
                <div>
                    <label>
                        <input
                            type="radio"
                            checked={isOrgAdmin}
                            onChange={() => setIsOrgAdmin(true)}
                        />
                        &nbsp;<span style={{ fontSize: "1.4em", fontWeight: "bold" }}>Admin</span> (can manage users, access all teams)
                    </label>
                    <br />
                    <label>
                        <input
                            type="radio"
                            checked={!isOrgAdmin}
                            onChange={() => setIsOrgAdmin(false)}
                        />
                        &nbsp;<span style={{ fontSize: "1.4em", fontWeight: "bold" }}>Team Manager</span> (can access teams created by them and admin-approved teams)
                    </label>
                </div>
            }
            {!isOrgAdmin && isEditing && (
                <div className="three_with_small_mid_section">
                    <div>
                        <label>Available Teams</label>
                        <select
                            multiple
                            size={5}
                            value={selectedAvailableTeams}
                            onChange={(e) => setSelectedAvailableTeams([...e.target.selectedOptions].map(o => o.value))}>
                            {availableTeams.map((team) => (
                                <option key={team.team_hash} value={team.team_hash}>{team.team_name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <button className="small_button" onClick={() => moveTeams(availableTeams, selectedTeams, setAvailableTeams, setSelectedTeams, selectedAvailableTeams)}>→</button>
                        <button className="small_button" onClick={() => moveTeams(selectedTeams, availableTeams, setSelectedTeams, setAvailableTeams, selectedSelectedTeams)}>←</button>
                    </div>
                    <div>
                        <label>Selected Teams</label>
                        <select
                            multiple
                            size={5}
                            value={selectedSelectedTeams}
                            onChange={(e) => setSelectedSelectedTeams([...e.target.selectedOptions].map(o => o.value))}>
                            {selectedTeams.map((team) => (
                                <option key={team.team_hash} value={team.team_hash}>{team.team_name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
            <div className="two_buttons">
                <button onClick={handleSubmit}>Submit</button>
                <button onClick={cancelEdit}>Cancel</button>
            </div>
        </div>
    );
};

export default OrgACL;
