 import '../styles/root.css';
import '../styles/summary.css';




const CertSummary = ({ accountInfo, profileData }) => {
    if (!accountInfo) {
        return null;
    }
    if (!profileData) {
        return null;
    }
    const LANG_LIMIT = 5;
    const SKILL_LIMIT = 7;
    const startDate = profileData.start_date;
    const endDate = profileData.end_date;
    const langs = profileData.langs;
    const topLangs = [];
    let linesOfCode = 0;
    let langCount = 0;
    let badgeCount = 0;
    for (let langMap of langs) {
        langCount += 1;
        if (langCount <= LANG_LIMIT) {
            topLangs.push(langMap['_key']);
        }
        linesOfCode += Math.round(langMap.mean * langMap.history)
        if (langMap['throughput_percentile'] > 0) {
            badgeCount += 1;
        }
        if (langMap['quality_percentile'] > 0) {
            badgeCount += 1;
        }
    }
    const skills = profileData.skills;
    const topSkills = [];
    let skillCount = 0;
    for (let skillMap of skills) {
        skillCount += 1;
        if (skillCount <= SKILL_LIMIT) {
            topSkills.push(skillMap['_key']);
        }
        // ignore throughput percentile
        // if (skillMap['throughput_percentile'] > 0) {
        //     badgeCount += 1;
        // }
        if (skillMap['level_percentile'] > 0) {
            badgeCount += 1;
        }
    }

    return (
        <div className='summary'>
            <div className="summary-header">
                <div className="summary-name">{accountInfo.first_name} {accountInfo.last_name}</div>
                <div>from <b>{startDate}</b> to <b>{endDate}</b></div>
            </div>
            <div className='summary-text'>
                <div className='summary-stats-container'>
                    <div className='summary-stats'>
                        modelteam.ai Verified with <b>{linesOfCode.toLocaleString()}</b> lines of code
                    </div>
                    <div className='summary-tops'>
                        {langCount > 0 && <div><em> {langCount}</em> prog. lang{langCount > 1 ? 's' : ''}</div>}
                        {skillCount > 0 && <div><em>{skillCount}</em> skill{skillCount > 1 ? 's' : ''}</div>}
                        {badgeCount > 0 && <div><em>{badgeCount}</em> badge{badgeCount > 1 ? 's' : ''}</div>}
                    </div>
                </div>
            </div>
        </div >
    );
}
export default CertSummary;