import React from 'react';
import { Link } from 'react-router-dom';
import badgeIcon from '../assets/icons/badge.svg';
import emailIcon from '../assets/icons/email.png';
import linkedinIcon from '../assets/icons/linkedin.svg';
import locationIcon from '../assets/icons/location.png';
import codqQualityIcon from '../assets/icons/quality-badge.svg';
import '../styles/profile.css';
import '../styles/root.css';
import { getProfileUrl, trimString } from '../utils/Utils';
import CopyTextToClipBoard from './CopyToClipBoard';

function getTopStats(badgeStats) {
    let top10 = badgeStats['top_10'] || 0;
    let top20 = badgeStats['top_20'] || 0;
    let top30 = badgeStats['top_30'] || 0;
    return [top10, top20 + top30];
}

const displayBadgeStats = (badgeStats, key) => {
    return <div className='badge-stats'>
        <table className='small_table'>
            <thead>
                <tr>
                    <th>{key}</th>
                    <th><img src={badgeIcon} title='Ranking' alt="Ranking" className='icon-td' /></th>
                    {key === 'Top Langs' ? <th><img src={codqQualityIcon} title="Code Quality" alt="Code Quality" className='icon-td' /></th> : <th></th>}
                </tr>
            </thead>
            <tbody>
                {badgeStats.map((badge, index) => {
                    const [top_10_thrp, top_30_thrp] = getTopStats(badge['thrp_stats']);
                    const [top_10_quality, top_30_quality] = getTopStats(badge['qual_stats']);
                    return <tr key={index}>
                        <td>{badge['_key']}</td>
                        <td>{top_10_thrp + top_30_thrp}</td>
                        {key === 'Top Langs' ? <td>{top_10_quality + top_30_quality}</td> : <td></td>}
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}

const displayTeamSummary = (teamSummary) => {
    return <div>
        <label>Team Size: {teamSummary['user_count']} 👤</label>
        {displayBadgeStats(teamSummary['langs'], 'Top Langs')}
        <br />
        {displayBadgeStats(teamSummary['skills'], 'Top Skills')}
    </div>
}



const AccountInfoDisplay = ({ summary, accountInfo }) => {
    if (!accountInfo) {
        return null;
    }
    const today = new Date();
    const currYear = today.getFullYear();
    const currMonth = today.getMonth() + 1;
    const expYear = currYear + 1;
    const profileId = accountInfo.email_hash;
    const profileUrl = getProfileUrl(profileId);
    const urlEncodedProfileUrl = encodeURIComponent(profileUrl);
    const liCertUrl = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=modelteam%20AI%20Verified%20Skills&organizationId=99187668&issueYear=${currYear}&issueMonth=${currMonth}&expirationYear=${expYear}&expirationMonth=${currMonth}&certUrl=${urlEncodedProfileUrl}&certId=${profileId}`;

    return <div className='profile-account-container'>
        <div className='profile-name-card'>
            <div className="profile-account-info">
                <div className="profile-account-name">{accountInfo.first_name} {accountInfo.last_name}</div>
                {accountInfo.team_summary ? <div className="profile-account-team">{displayTeamSummary(accountInfo.team_summary)}</div> :
                    <div>
                        <div className="profile-account-text">
                            {accountInfo.country && <>
                                <img src={locationIcon} alt="country" className='icon' />
                                {accountInfo.country}&nbsp;
                            </>}
                            {accountInfo.personal_email && (
                                <a href={`mailto:${accountInfo.personal_email}`}>
                                    <img src={emailIcon} alt="email" className='icon' />
                                </a>
                            )}
                            {accountInfo.linkedin_url && (
                                <a href={accountInfo.linkedin_url} target='_blank' rel="noreferrer">
                                    <img src={linkedinIcon} alt="linkedin" className='icon' />
                                </a>
                            )}
                        </div>
                    </div>
                }
            </div>
            {accountInfo.is_self && <div className='profile-account-edit'>
                <CopyTextToClipBoard urlText={profileUrl} isUrl={true} displayText={false} />
                <a href={liCertUrl} target="_blank" rel="noreferrer">
                    <img src="https://download.linkedin.com/desktop/add2profile/buttons/en_US.png" alt="LinkedIn Add to Profile button" />
                </a>
            </div>}
        </div>
        <div className='profile-account-about-container'>
            {(accountInfo.about_me || summary) && <div className='profile-account-about-title'>Summary</div>}
            {accountInfo.about_me && <div className="profile-account-about-text">{trimString(accountInfo.about_me, 100)}</div>}
            {summary &&
                <div className="profile-account-about-text">
                    {trimString(summary, 500).split("\n").map((line, index) => {
                        const parts = line.split(":");
                        return (
                            <div key={index}>
                                <strong>{parts[0]}</strong>
                                {parts.length > 1 && `: ${parts.slice(1).join(":")}`}
                                <br />
                            </div>
                        );
                    })}
                </div>
            }
        </div>
        {accountInfo.cta && <Link to='/account'><button className='button-cta'>Sign up to get your own profile✨</button></Link>}
    </div>;
}
export default AccountInfoDisplay;