import React, { useEffect, useRef, useState } from 'react';
import { DomainSkills, DomainSkillsWithBaseline, ProgLangs, ProgLangsWithBaseline } from '../components/Skills';
import { ALL_COMPANY_TEAM } from '../configs/config';
import '../styles/profile.css';
import '../styles/root.css';
import { generateQuarters, getLangWeightedScore, getSkillWeightedScore } from '../utils/Utils';
import Tab from './Tab';


const TabContent = ({ summary, activeTab, profileData, numYears, baselines, accountInfo, baselineAccountInfos, recipeSkillsAndLangs }) => {
  if (activeTab === 'Skills') {
    if (baselines && baselines.length > 0) {
      return <DomainSkillsWithBaseline summary={summary} skillsData={profileData.skills} numYears={numYears} baselines={baselines ? baselines.map(baseline => baseline.skills) : null} accountInfo={accountInfo} baselineAccountInfos={baselineAccountInfos} priorityList={recipeSkillsAndLangs} />;
    } else {
      return <DomainSkills summary={summary} skillsData={profileData.skills} numYears={numYears} accountInfo={accountInfo} />;
    }
  }
  if (activeTab === 'Languages') {
    if (baselines && baselines.length > 0) {
      return <ProgLangsWithBaseline summary={summary} langsData={profileData.langs} numYears={numYears} baselines={baselines ? baselines.map(baseline => baseline.langs) : null} accountInfo={accountInfo} baselineAccountInfos={baselineAccountInfos} priorityList={recipeSkillsAndLangs} />;
    } else {
      return <ProgLangs summary={summary} langsData={profileData.langs} numYears={numYears} accountInfo={accountInfo} />;
    }
  }
  if (activeTab === 'Verify') {
    return <div className='verification-container'>
      <table className="table-with-border">
        <thead>
          <tr>
            <th>Company</th>
            <th>GitEmailID</th>
          </tr>
        </thead>
        <tbody>
          {profileData.verification.map((verification, index) => (
            <tr key={index}>
              {/* workEmail, company, gitEmailID */}
              <td>{verification[1]}</td>
              <td>{verification[2]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>;
  }
  return null;
};

const ProfileTab = ({ profileData, baselines, accountInfo, baselineAccountInfos, numYears = 3, recipeSkillsAndLangs }) => {
  const [activeTab, setActiveTab] = useState('Skills');
  const [selectedOption, setSelectedOption] = useState(numYears);
  const outerDivRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(true);
  const [summary, setSummary] = useState('');
  const quarters = generateQuarters(8);

  useEffect(() => {
    const handleScroll = () => {
      if (outerDivRef.current) {
        if (outerDivRef.current.scrollTop === 0) {
          setIsAtTop(true);
        } else {
          setIsAtTop(false);
        }
      }
    };


    const outerDiv = outerDivRef.current;
    if (outerDiv) {
      outerDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (outerDiv) {
        outerDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  function extractSkillExpertise(skills) {
    let skillExpertise = [];
    let skillGoodAt = [];
    for (let i = 0; i < skills.length; i++) {
      if (skills[i].level_percentile !== 0 && skills[i].num_quarters > 2 && skillExpertise.length < 5) {
        skillExpertise.push(skills[i]._key);
      }
    }
    if (skillExpertise.length < 3) {
      for (let i = 0; i < skills.length; i++) {
        if (skills[i].level_percentile === 0 && skillGoodAt.length < (3 - skillExpertise.length)) {
          skillGoodAt.push(skills[i]._key);
        }
      }
    }
    return { skillExpertise, skillGoodAt };
  }

  function extractLangExpertise(langs) {
    let langExpertise = [];
    let langGoodAt = [];
    for (let i = 0; i < langs.length; i++) {
      if (langs[i].throughput_percentile !== 0 && langs[i].history > 2 && langExpertise.length < 3) {
        langExpertise.push(langs[i]._key);
      }
    }
    if (langExpertise.length < 3) {
      for (let i = 0; i < langs.length; i++) {
        if (langs[i].throughput_percentile === 0 && langGoodAt.length < (2 - langExpertise.length)) {
          langGoodAt.push(langs[i]._key);
        }
      }
    }
    return { langExpertise, langGoodAt };
  }

  const generateUserSummary = (profileData) => {
    const skills = profileData.skills;
    const langs = profileData.langs;
    let { skillExpertise, skillGoodAt } = extractSkillExpertise(skills);
    let { langExpertise, langGoodAt } = extractLangExpertise(langs);
    let summary = '';
    if (skillExpertise.length > 0 || langExpertise.length > 0) {
      summary += 'Expert: ';
      if (skillExpertise.length > 0) {
        summary += skillExpertise.join(', ');
      }
      if (langExpertise.length > 0) {
        if (skillExpertise.length > 0) {
          summary += ' & ';
        }
        summary += langExpertise.join(', ');
      }
    }
    if (skillGoodAt.length > 0 || langGoodAt.length > 0) {
      if (summary.length > 0) {
        summary += '.\n';
      }
      summary += 'Good: ';
      if (skillGoodAt.length > 0) {
        summary += skillGoodAt.join(', ');
      }
      if (langGoodAt.length > 0) {
        if (skillGoodAt.length > 0) {
          summary += ' & ';
        }
        summary += langGoodAt.join(', ');
      }
    }
    if (summary.length > 0) {
      summary += '.';
    }
    return summary;
  }

  const generateWeightedScores = (skillsOrLangs, isSkill) => {
    const skillMap = {};
    for (let i = 0; i < skillsOrLangs.length; i++) {
      if (!('weighted_score' in skillsOrLangs[i])) {
        if (isSkill) {
          skillsOrLangs[i].weighted_score = getSkillWeightedScore(skillsOrLangs[i], quarters);
        } else {
          skillsOrLangs[i].weighted_score = getLangWeightedScore(skillsOrLangs[i], quarters);
        }
      }
      skillMap[skillsOrLangs[i]._key] = skillsOrLangs[i].weighted_score;
    }
    return skillMap;
  }

  function generateSkillComparisonSummary(baselineSkills, baselineSkillsWS, skills, userSkillsWS, limit, overlaps, gaps, outStandings) {
    const skillSet = new Set();
    for (let i = 0; i < Math.min(baselineSkills.length, limit); i++) {
      const bWS = baselineSkills[i].weighted_score;
      if (!(baselineSkills[i]._key in userSkillsWS)) {
        gaps.push(baselineSkills[i]._key);
        skillSet.add(baselineSkills[i]._key);
        continue;
      }
      const uWS = userSkillsWS[baselineSkills[i]._key];
      const ratio = uWS / bWS;
      if (ratio > 1.2) {
        skillSet.add(baselineSkills[i]._key);
        outStandings.push(baselineSkills[i]._key);
      } else if (ratio > 0.8) {
        skillSet.add(baselineSkills[i]._key);
        overlaps.push(baselineSkills[i]._key);
      } else if (ratio < 0.5) {
        skillSet.add(baselineSkills[i]._key);
        gaps.push(baselineSkills[i]._key);
      }
    }
    for (let i = 0; i < Math.min(skills.length, limit); i++) {
      if (skillSet.has(skills[i]._key)) {
        continue;
      }
      const uWS = skills[i].weighted_score;
      if (!(skills[i]._key in baselineSkillsWS)) {
        outStandings.push(skills[i]._key);
        continue;
      }
      const bWS = baselineSkillsWS[skills[i]._key];
      const ratio = uWS / bWS;
      if (ratio > 1.5) {
        outStandings.push(skills[i]._key);
      } else if (ratio > 0.8) {
        overlaps.push(skills[i]._key);
      } else if (ratio < 0.5) {
        gaps.push(skills[i]._key);
      }
    }

  }

  const generateOverlapSummary = (profileData, baseline) => {
    const skills = profileData.skills;
    const langs = profileData.langs;
    const baselineSkills = baseline.skills;
    const baselineLangs = baseline.langs;
    let summary = '';
    const userSkillsWS = generateWeightedScores(skills, true);
    const userLangsWS = generateWeightedScores(langs, false);
    const baselineSkillsWS = generateWeightedScores(baselineSkills, true);
    const baselineLangsWS = generateWeightedScores(baselineLangs, false);
    let overlaps = [];
    let gaps = [];
    let outStandings = [];
    generateSkillComparisonSummary(baselineSkills, baselineSkillsWS, skills, userSkillsWS, 5, overlaps, gaps, outStandings);
    generateSkillComparisonSummary(baselineLangs, baselineLangsWS, langs, userLangsWS, 3, overlaps, gaps, outStandings);
    let cmpSummary = '';
    if (outStandings.length > 0) {
      cmpSummary += 'Expert: ';
      cmpSummary += outStandings.join(', ');
      cmpSummary += '. ';
    }
    if (overlaps.length > 0) {
      if (cmpSummary.length > 0) {
        cmpSummary += '\n';
      }
      cmpSummary += 'Good: ';
      cmpSummary += overlaps.join(', ');
      cmpSummary += '. ';
    }
    if (gaps.length > 0) {
      if (cmpSummary.length > 0) {
        cmpSummary += '\n';
      }
      cmpSummary += 'Gaps: ';
      cmpSummary += gaps.join(', ');
      cmpSummary += '.';
    }
    if (cmpSummary.length > 0) {
      if (summary.length > 0) {
        summary += '\n';
      }
      summary += cmpSummary;
    }
    if (summary.length === 0) {
      summary = generateUserSummary(profileData);
    }
    return summary;
  }

  const generateSummary = (profileData, baselines) => {
    let summary = '';
    if (!baselines || baselines.length === 0) {
      return generateUserSummary(profileData);
    }
    if (baselines) {
      let baseline = null;
      for (let i = 0; i < baselines.length; i++) {
        if (baselines.length > 1 && baselines[i].user.endsWith(ALL_COMPANY_TEAM)) {
          continue;
        } else {
          baseline = baselines[i];
          break;
        }
      }
      if (baseline) {
        summary = generateOverlapSummary(profileData, baseline);
      }
    }
    return summary;
  };


  useEffect(() => {
    if (profileData?.langs) {
      let maxDate = '0000-00'; // Ensuring a valid initial date format

      profileData.langs.forEach(langItem => {
        langItem.date_vector.forEach(date => {
          if (date > maxDate) {
            maxDate = date;
          }
        });
      });

      if (maxDate !== '0000-00') {
        const maxYear = parseInt(maxDate.substring(0, 4), 10);
        const today = new Date();
        const currentYear = today.getFullYear();
        const yearsDiff = Math.round((currentYear - maxYear) + today.getMonth() / 12);

        let numYears = yearsDiff <= 1 ? 1 : yearsDiff <= 3 ? 3 : yearsDiff <= 5 ? 5 : 10;
        setSelectedOption(numYears);
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData && accountInfo) {
      if (!accountInfo.team_summary) {
        setSummary(generateSummary(profileData, baselines))
      } else {
        setSummary('');
      }
    }
  }, [profileData, accountInfo, baselines]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  function isAllVerified() {
    return false;
  }
  if (!profileData) {
    profileData = {
      skills: [],
      langs: [],
    };
  }
  return (
    <div className="profile-tabs-container">
      <div className="profile-tabs-bar">
        <div className="profile-tabs">
          <Tab name="Skills" isActive={activeTab === 'Skills'} onClick={() => setActiveTab('Skills')} />
          <Tab name="Langs" isActive={activeTab === 'Languages'} onClick={() => setActiveTab('Languages')} />
          {profileData.verification && <Tab name={"Verify" + (isAllVerified() ? '' : '*')} isActive={activeTab === 'Verify'} onClick={() => setActiveTab('Verify')} />}
          <div className="dropdown-container">
            <label className='whoami'>Showing</label>
            <select id="dropdown" name='dropdown' value={selectedOption} onChange={handleChange} className='custom-select'>
              <option value="1">1</option>
              <option value="3">3</option>
              <option value="5">5</option>
              <option value="10">10</option>
            </select>
            <label className='whoami'>Years</label>
          </div>
        </div>
        {accountInfo ? <div className="profile-header">
          {isAtTop ? null : accountInfo.first_name} {isAtTop ? null : accountInfo.last_name}
        </div> : <div className='profile-header'></div>}
      </div>
      <div className="profile-charts" ref={outerDivRef}>
        <TabContent summary={summary} activeTab={activeTab} profileData={profileData} numYears={Math.round(selectedOption)} baselines={baselines} accountInfo={accountInfo} baselineAccountInfos={baselineAccountInfos} recipeSkillsAndLangs={recipeSkillsAndLangs} />
      </div>
    </div>
  );
}

export default ProfileTab;
