// Layout.jsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/profile.css';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import Background from './Background';
import Footer from './Footer';
import Header from './Header';
import ProgressTracker from './ProgressTracker';


const OrgLayout = ({ children, isLoggedIn, reloadStatus }) => {
  const currPage = window.location.pathname;
  const navigate = useNavigate();
  const [status, setStatus] = useState(localStorage.getItem('org_status') || 0);
  const userApiPrefix = '/api/v1/org/user/status';

  const steps = [
    { name: "Company", path: "/org/account" },
    { name: "Edit Teams", path: "/org/teams" },
    { name: "Analyze Teams", path: "/org/teamprof" },
    { name: "Jobs", path: "/org/jobs" }
  ];

  useEffect(() => {
    if (isLoggedIn) {
      getStatus();
    }
  }, [navigate, isLoggedIn]);

  useEffect(() => {
    if (reloadStatus) {
      getStatus();
    }
  }, [reloadStatus]);

  async function getStatus() {
    const response = await fetchAPIWithToken(navigate, `${userApiPrefix}`, 'GET', {});
    if (response.status === 200) {
      const data = await response.json();
      localStorage.setItem('org_status', data.status);
      setStatus(data.status);
    }
  }

  function goto(path) {
    if (!isLoggedIn) {
      return;
    }
    navigate(path);
  }

  function getIndexOfPath(path) {
    path = path.split("?")[0];
    if (path === "/org") {
      return 0;
    }
    if (path === "/org/copilot") {
      return 3;
    }
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].path === path) {
        return i;
      }
    }
    return 0;
  }

  return (
    <div className="content">
      <Header />
      <Background />
      <ProgressTracker currPage={currPage} goto={goto} status={status} steps={steps} showTrack={false} getIndexOf={getIndexOfPath} />
      <main>
        {children}
      </main>
      <Footer />
    </div >
  );
}

export default OrgLayout;
