import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import deleteIcon from '../assets/icons/delete.png'; // 🗑️ Delete
import downIcon from '../assets/icons/down.png'; // ⬇️ Down
import editIcon from '../assets/icons/edit.png'; // ✏️ Edit
import upIcon from '../assets/icons/up.png'; // ⬆️ Up
import { ALL_COMPANY_TEAM } from '../configs/config';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import OrgACL from './OrgACL';

const usersAclApiPrefix = '/api/v1/org/acl/users';
const userAclApi = '/api/v1/org/acl/user';
const teamPrefix = '/api/v1/org/team';
const teamsApiPrefix = teamPrefix + '/teams';

const OrgAdminView = () => {
    const navigate = useNavigate();
    const [admins, setAdmins] = useState([]);
    const [managers, setManagers] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [teams, setTeams] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [currUser, setCurrUser] = useState(null);

    useEffect(() => {
        loadUsers();
        loadTeams();
    }, [navigate]);

    async function saveUser(user) {
        if (addNew) {
            const response = await fetchAPIWithToken(navigate, userAclApi, 'POST', user);
            if (response.status === 200) {
                loadUsers();
                setIsEditing(false);
                setAddNew(false);
            } else {
                const json_resp = await response.json();
                alert('Failed to add user - ' + json_resp.error);
            }
        } else {
            const response = await fetchAPIWithToken(navigate, userAclApi, 'PUT', user);
            if (response.status === 200) {
                loadUsers();
                setIsEditing(false);
            } else {
                const json_resp = await response.json();
                alert('Failed to update user - ' + json_resp.error);
            }
        }
    }

    function cancelEdit() {
        setIsEditing(false);
    }


    const loadTeams = async () => {
        const response = await fetchAPIWithToken(navigate, teamsApiPrefix, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            // filter ALL_COMPANY_TEAM
            setTeams(data.filter((team) => team.team_name !== ALL_COMPANY_TEAM));
        } else {
            setTeams([]);
        }
    };


    async function changeMode(user, isAdmin) {
        // show confirmation dialog
        const message = isAdmin ? `Upgrade ${user.user_name} to Admin?` : `Downgrade ${user.user_name} to Team Manager?`;
        if (!window.confirm(message)) {
            return;
        }
        user.is_org_admin = isAdmin;
        const response = await fetchAPIWithToken(navigate, `${userAclApi}`, 'PUT', user);
        if (response.status === 200) {
            loadUsers();
        } else {
            const json_resp = await response.json();
            alert('Failed to update user role - ' + json_resp.error);
        }
    }

    function displayAdmins() {
        return (
            <div>
                <h2>Admins</h2>
                <label>Admins can manage users and access all teams</label>
                {admins.length > 0 &&
                    <table className='table-with-border-oss'>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Downgrade</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {admins.map((user) => (
                                <tr key={user.user_email_hash}>
                                    <td>{user.user_name} - {user.user_email}</td>
                                    <td>
                                        <img src={downIcon} title='Downgrade to Team Manager'
                                            className='icon-btn'
                                            onClick={() => changeMode(user, false)} />
                                    </td>
                                    <td>
                                        <img src={deleteIcon} title='Delete User'
                                            className='icon-btn'
                                            onClick={() => deleteTeam(user)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        )
    }

    function editTeam(user) {
        setIsEditing(true);
        setAddNew(false);
        setCurrUser(user);
    }

    async function deleteTeam(user) {
        // show confirmation dialog
        if (!window.confirm(`Delete User: ${user.user_name}?`)) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${userAclApi}`, 'DELETE', user);
        if (response.status === 200) {
            loadUsers();
        } else {
            const json_resp = await response.json();
            alert('Failed to delete user - ' + json_resp.error);
        }
    }

    function displayManagers() {
        return (
            <div>
                <h2>Team Managers</h2>
                <label>Team Managers can access the teams created by them and admin-approved teams</label>
                {managers.length > 0 &&
                    <table className='table-with-border-oss'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Edit Teams</th>
                                <th>Upgrade</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {managers.map((user) => (
                                <tr key={user.user_email_hash}>
                                    <td>{user.user_name} - {user.user_email}</td>
                                    <td>
                                        <img src={editIcon} title='Add/Remove Team'
                                            className='icon-btn'
                                            onClick={() => editTeam(user)} />
                                    </td>
                                    <td>
                                        <img src={upIcon} title='Upgrade to Admin'
                                            className='icon-btn'
                                            onClick={() => changeMode(user, true)} />
                                    </td>
                                    <td>
                                        <img src={deleteIcon}
                                            className='icon-btn' title='Delete User'
                                            onClick={() => deleteTeam(user)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        )
    }

    async function loadUsers() {
        const response = await fetchAPIWithToken(navigate, `${usersAclApiPrefix}`, 'GET', {});
        if (response.status === 200) {
            const data = await response.json();
            const admins = [];
            const managers = [];
            for (const user of data) {
                if (user.is_org_admin) {
                    admins.push(user);
                } else {
                    managers.push(user);
                }
            }
            setAdmins(admins);
            setManagers(managers);
        } else {
            setAdmins([]);
            setManagers([]);
        }
    }

    const addUser = () => {
        setIsEditing(true);
        setAddNew(true);
        setCurrUser(null);
    }

    return (
        <div>
            <br />
            {!isEditing && <button className='small_button' onClick={addUser}>Add New User</button>}
            {isEditing ?
                <OrgACL teams={teams} saveUser={saveUser} cancelEdit={cancelEdit} currUser={currUser} isEditing={!addNew} />
                :
                <div className='flex-container'>
                    <div>
                        {displayAdmins()}
                    </div>
                    <div>
                        {displayManagers()}
                    </div>
                </div>
            }
        </div>
    );
};

export default OrgAdminView;
