// src/Jobs.js
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MultiSelect from '../components/MultiSelect';
import OrgLayout from '../components/OrgLayout';
import TagInput from '../components/TagInput';
import '../styles/account.css';
import '../styles/jobs.css';
import '../styles/org.css';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { isOrgTokenExpired, ORG_PREFIX } from '../utils/CheckToken';
import { formatDate } from '../utils/DateTimeUtils';

const teamPrefix = '/api/v1/org/team';
const teamsApiPrefix = teamPrefix + '/teams';
const teamApiPrefix = teamPrefix + '/team';
const teamApiWGPrefix = teamPrefix + '/teamwg';
const cohortApiPrefix = teamPrefix + '/cohort';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const OrgTeams = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const query = useQuery();
    const [teamHash, setTeamHash] = useState(query.get('th') || sessionStorage.getItem(ORG_PREFIX + 'team_hash') || '');
    const [orgAccountUserEmailHash, setOrgAccountUserEmailHash] = useState(localStorage.getItem(ORG_PREFIX + 'email_hash'));
    const [teams, setTeams] = useState([]);
    const [selectedTeamIndex, setSelectedTeamIndex] = useState(-1);
    const [teamMembers, setTeamMembers] = useState([]);
    const [fileName, setFileName] = useState('');
    const [cohortMembers, setCohortMembers] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [selectedCohortIndex, setSelectedCohortIndex] = useState(-1);
    const [addNew, setAddNew] = useState(false);
    const [editTeam, setEditTeam] = useState(false);
    const [reloadTeams, setReloadTeams] = useState(false);
    const [addNewCohort, setAddNewCohort] = useState(false);
    const [reloadTeam, setReloadTeam] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [selectedOption, setSelectedOption] = useState('DIY');
    const [repos, setRepos] = useState([]);
    const [refrehInterval, setRefrehInterval] = useState(0);
    const [currCohort, setCurrCohort] = useState({
        user_hash: 'test',
        user_email: '',
        cohort_members: [],
        is_cohort: true
    });
    const [currTeam, setCurrTeam] = useState({
        org_email_hash: '',
        team_name: '',
        team_hash: '',
        skill_profile_name: '',
        repo_list: [],
        display_profile: '',
        skill_profile_status: 0,
        deleted: false,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCurrTeam(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if (editTeam) {
            if (currTeam.repo_list && currTeam.repo_list.length > 0 && !currTeam.skill_profile_name) {
                setSelectedOption('White Glove');
            } else if ((!currTeam.repo_list || currTeam.repo_list.length === 0) && currTeam.skill_profile_name) {
                setSelectedOption('DIY');
            }
        }
    }, [editTeam]);

    useEffect(() => {
        if (refrehInterval === 0) return;
        const timer = setTimeout(() => {
            loadTeam();
        }, refrehInterval);
        return () => clearTimeout(timer);
    }, [refrehInterval]);

    useEffect(() => {
        if (currTeam.skill_profile_status === 1 && refrehInterval !== 600000) {
            setRefrehInterval(600000);
        } else if (currTeam.skill_profile_status !== 1 && refrehInterval !== 0) {
            setRefrehInterval(0);
        }
    }, [currTeam, refrehInterval]);

    useEffect(() => {
        setIsLoggedIn(!isOrgTokenExpired());
        setIsLoginChecked(true);
        currTeam['org_email_hash'] = orgAccountUserEmailHash;
        loadTeams();
    }, [navigate]);

    useEffect(() => {
        if (reloadTeams) {
            loadTeams();
            currTeam['org_email_hash'] = orgAccountUserEmailHash;
            setReloadTeams(false);
        }
    }, [reloadTeams]);

    useEffect(() => {
        loadTeam();
        loadTeamMembers(teamHash);
        setAddNewCohort(false);
        setEditTeam(false);
        if (teamHash) {
            sessionStorage.setItem(ORG_PREFIX + 'team_hash', teamHash);
        }
    }, [teamHash, reloadTeam]);

    useEffect(() => {
        if (currCohort && currCohort.cohort_members) {
            setCohortMembers(currCohort.cohort_members);
        }
    }, [currCohort]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/org/login?redirect=/org/teams`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    const loadTeam = async () => {
        if (teamHash) {
            const response = await fetchAPIWithToken(navigate, `${teamApiPrefix}?team_hash=${teamHash}`, 'GET', {});
            if (response.status !== 200) {
                console.error(response);
                return;
            }
            const data = await response.json();
            setCurrTeam(data);
            if (data.repo_list) {
                setRepos(data.repo_list);
            }
        } else {
            setCurrTeam({
                org_email_hash: orgAccountUserEmailHash,
                team_name: '',
                team_hash: '',
                skill_profile_name: '',
                display_profile: '',
                skill_profile_status: 0,
                deleted: false,
            });
        }
    }

    const loadTeamMembers = async (teamHash) => {
        if (!teamHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${teamPrefix}/members?team_hash=${teamHash}&minstatus=1`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        const members = data.users
        const cohorts = data.cohorts
        if (members.length > 0) {
            setTeamMembers(members);
        } else {
            setTeamMembers([]);
        }
        if (cohorts.length > 0) {
            setCohorts(cohorts);
            setSelectedCohortIndex(0);
            setCurrCohort(cohorts[0]);
        } else {
            setCohorts([]);
            setCurrCohort(null);
        }
    };

    const loadTeams = async () => {
        if (!orgAccountUserEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, teamsApiPrefix, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            setTeams(data);
            setAddNew(false);
            if (selectedTeamIndex === -1 && !teamHash) {
                setSelectedTeamIndex(0);
                setTeamHash(data[0].team_hash);
                setCurrTeam(data[0]);
            } else if (teamHash) {
                const index = data.findIndex(team => team.team_hash === teamHash);
                if (index !== -1) {
                    setSelectedTeamIndex(index);
                    setCurrTeam(data[index]);
                } else {
                    setSelectedTeamIndex(0);
                    setTeamHash(data[0].team_hash);
                    setCurrTeam(data[0]);
                }
            }
        } else {
            setTeams([]);
            setAddNew(true);
            setTeamHash('');
            setCurrTeam({
                org_email_hash: orgAccountUserEmailHash,
                team_name: '',
                team_hash: '',
                skill_profile_name: '',
                repo_list: [],
                merged_profile: '',
                skill_profile_status: 0,
                deleted: false,
            });
        }
    };

    function validateTeam() {
        const fileInput = document.getElementById('profile');
        if (!currTeam.team_name) {
            alert('Enter Team Name');
            return false;
        }
        // skill profile is optional while creating team
        if (teamHash && (!fileInput || fileInput.files.length === 0)) {
            alert('Stats file not selected to upload');
            return false;
        }
        return true;
    }

    const deleteTeam = async () => {
        if (teamHash) {
            if (!currTeam.deleted) {
                if (!window.confirm('Are you sure you want to delete this team?. This can\'t be undone.')) {
                    return;
                }
            }
            const response = await fetchAPIWithToken(navigate, teamApiPrefix + '?team_hash=' + teamHash, 'DELETE', currTeam);
            if (response.status === 200) {
                setReloadTeams(true);
            } else {
                console.error(response);
            }
        }
    }

    const createOrUpdateTeam = async () => {
        if (validateTeam()) {
            const fileInput = document.getElementById('profile');
            const formData = new FormData();
            formData.append('org_email_hash', currTeam.org_email_hash);
            formData.append('team_name', currTeam.team_name);
            if (fileInput && fileInput.files.length > 0) {
                const fileName = fileInput.files[0].name;
                if (fileName.endsWith('.gz') && (fileName.startsWith('mt_stats.json') || fileName.startsWith('mt_profile.json'))) {
                    formData.append('file', fileInput.files[0]);
                    if (teamHash) {
                        formData.append('team_hash', teamHash);
                    }
                } else {
                    console.error('Invalid file format');
                    alert('Invalid file format');
                    return;
                }
            }
            try {
                const response = await fetchAPIWithToken(navigate, teamApiPrefix, teamHash ? 'PUT' : 'POST', formData, true, true);
                if (response.status !== 201 && response.status !== 200) {
                    console.error(response);
                    const data = await response.json();
                    teamHash ? alert('Error updating team - ' + data.error) : alert('Error creating team - ' + data.error);
                    return;
                }
                if (!teamHash) {
                    const data = await response.json();
                    setTeamHash(data.team_hash);
                    setAddNew(false);
                } else {
                    setEditTeam(false);
                }
                setReloadTeams(true);
                if (fileInput) {
                    fileInput.value = '';
                }
            } catch (error) {
                console.error(error);
                alert('Error uploading file');
            }
        }
    }

    const validateTeamWG = () => {
        if (currTeam.team_name && repos.length > 0) {
            return true;
        }
        return false;
    }

    const createOrUpdateTeamWG = async () => {
        if (validateTeamWG()) {
            try {
                currTeam['repo_list'] = repos;
                const response = await fetchAPIWithToken(navigate, teamApiWGPrefix, teamHash ? 'PUT' : 'POST', currTeam);
                if (response.status !== 201 && response.status !== 200) {
                    console.error(response);
                    const data = await response.json();
                    teamHash ? alert('Error updating team - ' + data.error) : alert('Error creating team - ' + data.error);
                    return;
                }
                if (!teamHash) {
                    const data = await response.json();
                    setTeamHash(data.team_hash);
                    setAddNew(false);
                } else {
                    setEditTeam(false);
                }
                setReloadTeams(true);
                alert('Success! Profile will be ready to view shortly.');
            } catch (error) {
                console.error(error);
                alert('Error uploading file');
            }
        } else {
            alert('Please fill all fields');
        }
    }


    const explainStatus = (name, status) => {
        let statusText = <h3 style={{ color: 'red' }}>No Profile</h3>;
        switch (status) {
            case 1:
                statusText = <h3 style={{ color: 'orange' }}>Profile Uploaded. AI Processing</h3>;
                break;
            case 2:
                statusText = <button className='small_button' onClick={() => navigate('/org/teamprof?th=' + currTeam.team_hash)}>View Team Profiles</button>
                break;
            default:
                statusText = <h3 style={{ color: 'red' }}>No Profile</h3>;
        }
        return <div className='two_columns'><h4>Profile {name} Status: </h4>{statusText}</div>;
    }

    const handleCohortChange = (event) => {
        const index = event.target.value;
        setSelectedCohortIndex(index);
        setCurrCohort(cohorts[index]);
    }

    const handleCohortMemSelect = (selectedIndices) => {
        const selectedMembers = selectedIndices.map(index => teamMembers[index]);
        setCohortMembers(selectedMembers);
    }

    const addNewCohortHandler = async () => {
        const user_email = document.getElementById('cohort').value;
        if (user_email && cohortMembers.length > 0) {
            const cohortMembersLite = cohortMembers.map(member => {
                return {
                    user_email: member.user_email,
                    user_hash: member.user_hash
                }
            });
            const cohort = {
                user_email: user_email,
                org_email_hash: orgAccountUserEmailHash,
                team_hash: teamHash,
                is_cohort: true,
                cohort_members: cohortMembersLite
            };
            const response = await fetchAPIWithToken(navigate, cohortApiPrefix, 'POST', cohort);
            if (response.status === 201) {
                setReloadTeam(!reloadTeam);
            } else {
                const data = await response.json();
                alert('Error creating cohort - ' + data.error);
                setCohortMembers([]);
                console.error(response);
            }
            setAddNewCohort(false);
        }
    }

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setFileName(event.target.files[0].name);
            setFileSelected(true);
            createOrUpdateTeam();
        } else {
            setFileName('');
            setFileSelected(false);
        }
    };

    const deleteCohort = async () => {
        if (selectedCohortIndex !== -1) {
            // check if cohort is not average_of_all_users. thats builtin cohort
            if (currCohort.user_email === 'average_of_all_users') {
                alert('Cannot delete this cohort');
                return;
            }
            const response = await fetchAPIWithToken(navigate, cohortApiPrefix + '?user_hash=' + currCohort.user_hash, 'DELETE', currCohort);
            if (response.status === 200) {
                setReloadTeam(!reloadTeam);
                alert('Cohort deleted');
            } else {
                console.error(response);
                const data = await response.json();
                alert('Error deleting cohort - ' + data.error);
            }
        }
    }

    const displayCohorts = () => {
        if (cohorts.length > 0) {
            return <div className='right-pane-teams-narrow'>
                <div className='two_columns'>
                    <div className='whoami'>
                        <label htmlFor='cohort'>Cohorts</label>
                        <select id='cohort' name='cohort' onChange={handleCohortChange} value={selectedCohortIndex} size={5} className='select_cohort'>
                            {cohorts.map((cohort, index) => (
                                <option key={index} value={index}>{cohort.user_email}{cohort.skill_profile_status === 2 ? ' (Ready) ' : ' (In Progress) '}</option>
                            ))}
                        </select>
                    </div>
                    <div className='whoami'>
                        <label htmlFor='members'>Members</label>
                        <select id='members' name='members' readOnly={true} size={5} className='select_cohort'>
                            {cohortMembers.map((member, index) => (
                                <option key={index} value={index}>{member.user_email}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='two_buttons'>
                    <button onClick={() => setAddNewCohort(true)} className='small_button'>Add New Cohort</button>
                    <button onClick={deleteCohort} className='red' disabled={selectedCohortIndex === -1}>Delete Cohort</button>
                </div>
            </div >;
        } else if (currTeam && currTeam.skill_profile_status === 2) {
            return <div className='whoami'>
                <h4>No Cohorts</h4>
                <button onClick={() => setAddNewCohort(true)} className='small_button'>Add New Cohort</button>
            </div>;
        }
    }

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const editTeamProfile = () => {
        setEditTeam(true);
    };

    const addNewTeam = () => {
        // FIXME: This is not updated to new handleFileChange
        let disableDIY = false;
        let disableWG = false;
        if (editTeam) {
            if (currTeam.repo_list && currTeam.repo_list.length > 0 && !currTeam.skill_profile_name) {
                disableDIY = true;
            } else if ((!currTeam.repo_list || currTeam.repo_list.length === 0) && currTeam.skill_profile_name) {
                disableWG = true;
            }
        }

        return (
            <div className='right-pane-teams'>
                {/* <div className='two_columns'>
                    <button onClick={() => handleOptionChange('DIY')} className={`job_div ${selectedOption === 'DIY' ? 'selected' : ''}`} disabled={disableDIY}>
                        DIY
                    </button>
                    <button onClick={() => handleOptionChange('White Glove')} className={`job_div ${selectedOption === 'White Glove' ? 'selected' : ''}`} disabled={disableWG}>
                        White Glove
                    </button>
                </div> */}

                {selectedOption === 'DIY' && (
                    <div className='team-container'>
                        <div className='whoami'>
                            <label htmlFor='team_name'>Team Name (cannot be modified after creation)*</label>
                            <input type='text' id='team_name' name='team_name' placeholder='Team Name' value={currTeam.team_name} readOnly={!addNew} onChange={handleChange} />
                        </div>
                        <div className='two_buttons'>
                            <button onClick={() => {
                                setEditTeam(false);
                                if (addNew) {
                                    setAddNew(false);
                                    if (teams.length > 0 && !teamHash) {
                                        setSelectedTeamIndex(0);
                                        setTeamHash(teams[0].team_hash);
                                        setCurrTeam(teams[0]);
                                    } else if (teams.length === 0) {
                                        navigate('/org/account');
                                    }
                                }
                            }} className='small_button'>Cancel</button>
                            <button onClick={createOrUpdateTeam} className='small_button'>Submit</button>
                        </div>
                    </div>
                )}

                {selectedOption === 'White Glove' && (
                    <div className='team-container'>
                        <p>
                            Please invite our <a href='https://github.com/modelteam-ai/modelteam.ai/blob/main/README_org_wg.md#modelteamai-bots' target='_blank' rel="noreferrer">bot</a> as a collobarotor to your repositories and we will build a profile for you.
                            You will be notified once the profile is ready.
                        </p>
                        <div className='whoami'>
                            <label htmlFor='team_name'>Team Name (cannot be modified after creation)</label>
                            <input type='text' id='team_name' name='team_name' placeholder='Team Name' value={currTeam.team_name} readOnly={!addNew} onChange={handleChange} />
                            <label htmlFor='repo_list'>Repo URL List</label>
                            <TagInput onTagsChange={setRepos} currVal={repos} />
                            <div className='two_buttons'>
                                <button onClick={() => { setEditTeam(false); setAddNew(false) }} className='small_button'>Cancel</button>
                                <button onClick={createOrUpdateTeamWG} disabled={repos.length <= 0} className='small_button'>Submit</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
    const displayTeam = () => {
        const isCompany = teams[selectedTeamIndex] && teams[selectedTeamIndex]['is_company'];
        if (addNew) {
            return addNewTeam();
        }
        if (teamHash) {
            if (addNewCohort) {
                return <div className='right-pane-teams'>
                    <div className='whoami'>
                        <div>
                            <label htmlFor='cohort'>Cohort Name</label>
                            <input type='text' id='cohort' name='cohort' placeholder='Cohort Name' />
                            <label htmlFor='members'>Members</label>
                            <MultiSelect items={teamMembers} maxSel={20} onSelectChange={handleCohortMemSelect} itemKey='user_email' />
                        </div>
                        <div className='two_buttons'>
                            <button onClick={() => setAddNewCohort(false)} className='small_button'>Cancel</button>
                            <button onClick={addNewCohortHandler} className='small_button'>Submit</button>
                        </div>
                    </div>
                </div>
            }
            else {
                return <div className='right-pane-teams'>
                    <div className='whoami'>
                        <label htmlFor='team_name'>Team Name</label>
                        <div className='two_columns'>
                            <input type='text' id='team_name' name='team_name' placeholder='Team Name' value={currTeam.team_name} readOnly={true} />
                            <button className='red' onClick={deleteTeam} disabled={isCompany}>Delete</button>
                        </div>
                        <label>(Newly updated profiles & New Cohorts will take around 30 minutes to be processed by our backend system)</label>
                        {explainStatus(currTeam.skill_profile_name, currTeam.skill_profile_status)}
                        {!isCompany &&
                            <div>
                                {currTeam.skill_profile_status === 0 ?
                                    <div className='flex_row'>
                                        <Link to='https://github.com/modelteam-ai/modelteam.ai/blob/main/README_org.md#getting-started' target='_blank'><button className='button-cta'>Build your Team profile ✨</button></Link>
                                        <label>Or</label>
                                        <Link to={`/org/jobs?th=${teamHash}`}><button className='button-cta'>Add your first job ✨</button></Link>
                                    </div>
                                    :
                                    <Link to='https://github.com/modelteam-ai/modelteam.ai/blob/main/README_org.md#getting-started' target='_blank'>Instructions to Update your Team profile ✨</Link>
                                }
                                {currTeam.skill_profile_status === 0 ?
                                    <span>
                                        <h3>Building Team profile is optional - if you just need to post a job and see candidate profiles</h3>
                                        <b>What to expect when building team profile:</b><br />
                                        0. <b>Do these on a machine where your codebase is checked out locally.</b><br />
                                        1. Set up your environment and download our models. (~10 mins)<br />
                                        2. Analyze your code commits locally to generate Team Skill stats. (1 min to analyze 1000 lines of code. ~1 hour)<br />
                                        3. Return here to upload the stats file.<br />
                                    </span>
                                    :
                                    <br />
                                }
                                <br />
                                <div className='whoami'>
                                    <label htmlFor="profile" className="small_button">
                                        <b>Upload Team Profile</b><br /><h5 className='no_margin_h2'>( mt_stats.json***.gz )</h5>
                                    </label>
                                    <input
                                        id="profile"
                                        type="file"
                                        name="profile"
                                        accept=".gz"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                        disabled={isCompany}
                                    />
                                    {fileSelected && <label>{fileName}</label>}
                                </div>
                                {/* <label htmlFor='profile'>Team Stats (mt_stats.json.gz) (Optional & Can be updated later)</label>
                            <input id='profile' type='file' name='profile' accept='.gz' onChange={handleFileChange} /> */}
                            </div>
                        }
                    </div>
                    {displayCohorts()}
                </div>
            }
        } else {
            return <div className='right-pane-teams'></div>
        }
    }

    const handleTeamClick = (index, clickedTeamHash) => {
        setSelectedTeamIndex(index);
        setTeamHash(clickedTeamHash);
        setAddNew(false);
    }

    const handleAddNew = () => {
        setSelectedTeamIndex(-1);
        setTeamHash('');
        setAddNew(true);
        setRepos([]);
    }

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    return (
        <OrgLayout isLoggedIn={isLoggedIn} reloadStatus={reloadTeams}>
            <div className='outer-box-top'>
                <div className='left-pane'>
                    {teams.map((team, index) => (
                        <div key={index} className={`job_div ${(selectedTeamIndex === index && !addNew) ? 'selected' : ''}`} onClick={() => handleTeamClick(index, team.team_hash)}>
                            <div>
                                <b>{team.team_name}</b>
                                <br />
                                <span>Updated on: {formatDate(team.updated_at)}</span>
                            </div>
                        </div>
                    ))}
                    {teams.length > 0 &&
                        <button onClick={() => handleAddNew()} className={`job_div  ${addNew ? 'selected' : ''}`}>
                            <b>Add New Team</b>
                        </button>
                    }
                </div>
                {displayTeam()}
            </div>
        </OrgLayout>
    );
};

export default OrgTeams;
