import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MultiSelect from '../components/MultiSelect';
import ProfileTab from '../components/ProfileTab';
import '../styles/root.css';
import '../styles/search.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { ORG_PREFIX } from '../utils/CheckToken';
import { generateQuarters, getLangWeightedScore, getSkillWeightedScore, trimString } from '../utils/Utils';


const profilesCache = {};
const teamProfileCache = {};

const OrgSkillSearch = () => {
    const navigate = useNavigate();
    const [orgAccountUserEmailHash, setOrgAccountUserEmailHash] = useState(localStorage.getItem(ORG_PREFIX + 'email_hash'));
    const [selectedTeamIndex, setSelectedTeamIndex] = useState(-1);
    const [teams, setTeams] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [currTeamHash, setCurrTeamHash] = useState(null);
    const [currTeamProfile, setCurrTeamProfile] = useState(null);
    const [teamSkills, setTeamSkills] = useState([]);
    const [teamLangs, setTeamLangs] = useState([]);
    const [selectedLangs, setSelectedLangs] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [reloadResults, setReloadResults] = useState(false);
    const [selectedUserIndices, setSelectedUserIndices] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [compareFlag, setCompareFlag] = useState(false);
    const [singleProfile, setSingleProfile] = useState(null);

    const teamPrefix = '/api/v1/org/team';
    const teamsApiPrefix = teamPrefix + '/teams?minstatus=2';
    const quarters = generateQuarters(8);

    useEffect(() => {
        if (singleProfile !== null || compareFlag) {
            window.history.pushState({ expanded: true }, ""); // Push a fake state
        }

        const handleBack = (event) => {
            if (singleProfile !== null || compareFlag) {
                setCompareFlag(false);
                setSingleProfile(null);
                window.history.replaceState(null, ""); // Remove fake state
                event.preventDefault(); // Prevent default back action
            }
        };

        window.addEventListener("popstate", handleBack);

        return () => {
            window.removeEventListener("popstate", handleBack);
        };
    }, [compareFlag, singleProfile]);

    useEffect(() => {
        loadTeams();
        setCurrTeamHash(null);
    }, [orgAccountUserEmailHash]);

    useEffect(() => {
        setReloadResults(false);
        if (!currTeamHash) {
            setTeamMembers([]);
            return;
        }
        loadTeamProfile(currTeamHash)
    }, [currTeamHash]);

    useEffect(() => {
        if (currTeamHash && currTeamProfile) {
            if (!currTeamProfile['display_profile']) {
                setTeamLangs([]);
                setTeamSkills([]);
                return;
            }
            const display_profile = currTeamProfile['display_profile']
            if (display_profile['langs'] === null) {
                setTeamLangs([])
            } else {
                setTeamLangs(display_profile['langs'])
            }
            if (display_profile['skills'] === null) {
                setTeamSkills([])
            } else {
                setTeamSkills(display_profile['skills'])
            }
        }
    }, [currTeamProfile]);

    useEffect(() => {
        if (!reloadResults) {
            return;
        }
        if (teamMembers && teamMembers.length > 0) {
            const results = teamMembers.map(member => {
                const dp = member.display_profile;
                var score = 0;
                member['search_summary'] = [];
                if (selectedLangs.length > 0) {
                    const langIDs = dp.langs.map(lang => lang.k_id);
                    for (let lang of selectedLangs) {
                        const langIndex = langIDs.indexOf(lang.k_id);
                        if (langIndex !== -1) {
                            const weightedScore = getLangWeightedScore(dp.langs[langIndex], quarters);
                            score += weightedScore
                            member['search_summary'].push({ name: lang._key, avg: weightedScore, q: dp.langs[langIndex].history, tp: dp.langs[langIndex].throughput_percentile });
                        } else {
                            return null;
                        }
                    }
                }
                if (selectedSkills.length > 0) {
                    const skillIDs = dp.skills.map(skill => skill.k_id);
                    for (let skill of selectedSkills) {
                        const skillIndex = skillIDs.indexOf(skill.k_id);
                        if (skillIndex !== -1) {
                            const weightedScore = getSkillWeightedScore(dp.skills[skillIndex], quarters);
                            score += weightedScore;
                            const qtr = dp.skills[skillIndex].num_quarters;
                            member['search_summary'].push({ name: skill._key, avg: weightedScore, q: qtr, tp: dp.skills[skillIndex].level_percentile });
                        } else {
                            return null;
                        }
                    }
                }
                return ({ member: member, score: score });
            });
            const sortedRes = results.filter(result => result !== null).sort((a, b) => b.score - a.score);
            setFilteredResults(sortedRes.map(result => result.member));
        } else {
            setFilteredResults([]);
        }
        setSelectedUserIndices([]);
        setSelectedUsers([]);
    }, [reloadResults, teamMembers, selectedLangs, selectedSkills]);


    const handleLangSelect = (selectedIndices) => {
        setReloadResults(false);
        setSelectedLangs(selectedIndices.map(index => teamLangs[index]));
    }

    const handleSkillSelect = (selectedIndices) => {
        setReloadResults(false);
        setSelectedSkills(selectedIndices.map(index => teamSkills[index]));
    }

    const loadTeamProfile = async (teamHash) => {
        if (!profilesCache[currTeamHash]) {
            const response = await fetchAPIWithToken(navigate, `${teamPrefix}/teamavg?team_hash=${currTeamHash}&team_name=${teams[selectedTeamIndex].team_name}`, 'GET', {});
            if (response.status !== 200) {
                console.error(response);
                return;
            }
            const data = await response.json();
            profilesCache[currTeamHash] = data;
        }
        setCurrTeamProfile(profilesCache[currTeamHash]);
    };

    const loadTeamMembers = async (teamHash) => {
        if (!teamHash) {
            return;
        }
        if (!teamProfileCache[teamHash]) {
            // Get team members and cohorts with status >= 2
            const response = await fetchAPIWithToken(navigate, `${teamPrefix}/users?team_hash=${teamHash}&minstatus=2`, 'GET', {});
            if (response.status !== 200) {
                console.error(response);
                return;
            }
            const members = await response.json();
            if (members && members.length > 0) {
                teamProfileCache[teamHash] = members;
            } else {
                teamProfileCache[teamHash] = [];
            }
        }
        setTeamMembers(teamProfileCache[teamHash]);
    };

    const loadTeams = async () => {
        if (!orgAccountUserEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${teamsApiPrefix}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            setTeams(data);
            if (selectedTeamIndex === -1) {
                setSelectedTeamIndex(0);
                setCurrTeamHash(data[0].team_hash);
            }
        } else {
            setTeams([]);
            setCurrTeamHash(null);
        }
    };


    const handleTeamSelectChange = (event) => {
        const index = event.target.value;
        if (!index || index === -1) {
            return;
        }
        setSelectedTeamIndex(index);
        setCurrTeamHash(teams[index].team_hash);
        setSelectedLangs([]);
        setSelectedSkills([]);
        setSelectedUserIndices([]);
        setSelectedUsers([]);
        setFilteredResults([]);
    };

    const clickCB = (event, index, result) => {
        event.stopPropagation(); // Prevent row click

        if (selectedUsers.includes(result)) {
            // Uncheck: Remove from selected lists
            setSelectedUsers(selectedUsers.filter(user => user.user_email !== result.user_email));
            setSelectedUserIndices(selectedUserIndices.filter(i => i !== index));
        } else {
            // Check: Add to selected lists if limit isn't exceeded
            if (selectedUsers.length < 5) {
                setSelectedUsers([...selectedUsers, result]);
                setSelectedUserIndices([...selectedUserIndices, index]);
            } else {
                alert("You can only select up to 5 users");
            }
        }
    };

    const compareProfiles = (singleProfile) => {
        if (selectedUsers.length > 0 || singleProfile) {
            const baselines = [currTeamProfile['display_profile']];
            const baselineAccountInfos = [currTeamProfile['account_info']];
            if (!singleProfile) {
                baselines.push(...selectedUsers.map(user => user.display_profile));
                baselineAccountInfos.push(...selectedUsers.map(user => user.account_info));
            }
            return <div>
                {
                    singleProfile ?
                        <ProfileTab profileData={singleProfile['display_profile']} accountInfo={singleProfile['account_info']} baselines={baselines} baselineAccountInfos={baselineAccountInfos} /> :
                        <ProfileTab baselines={baselines} baselineAccountInfos={baselineAccountInfos} numYears={1} />
                }
            </div>
        } else {
            return <div></div>
        }

    };

    const displayResults = (results) => {
        if (results === null || results.length === 0) {
            return <div>{reloadResults && <h4>No results found :(</h4>}</div>
        }
        if (compareFlag || singleProfile) {
            return compareProfiles(singleProfile);
        }
        return (
            <div className="skill-search-results-container">
                <div className='skill-search-results'>
                    <table className='table-with-border-oss'>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th className='wide_col'>Lang/Skill</th>
                                <th>Weighted Score <br />(last 2 years)</th>
                                <th>Quarters</th>
                                <th>Badges</th>
                                <th>Select (max 5)
                                    <button onClick={() => {
                                        setSingleProfile(null)
                                        setCompareFlag(true)
                                    }} className='small_button' disabled={selectedUserIndices.length < 2}>Compare</button>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='skill-search-results-container-scrollable'>
                    {results.map((row, rindex) => (
                        <div key={rindex} className='skill-search-results'>
                            {row && row.search_summary && (
                                <table className='table-with-border-oss'>
                                    <tbody className='clickable_col'>
                                        {row.search_summary.map((summary, index) => (
                                            <tr key={index}>
                                                {index === 0 && <td onClick={() => setSingleProfile(row)} rowSpan={row.search_summary.length} title={row.user_email}>{trimString(row.user_email, 30)}</td>}
                                                <td onClick={() => setSingleProfile(row)} className='wide_col'>{summary.name}</td>
                                                <td onClick={() => setSingleProfile(row)}>{summary.avg.toFixed(0)}</td>
                                                <td onClick={() => setSingleProfile(row)}>{summary.q}</td>
                                                <td onClick={() => setSingleProfile(row)}>{summary.tp !== 0 ? "Top " + summary.tp + "%" : ""}</td>
                                                {index === 0 && <td rowSpan={row.search_summary.length} onClick={(event) => clickCB(event, rindex, row)} className='non_clickable_col'>
                                                    <input type="checkbox" key={rindex} onChange={(event) => clickCB(event, rindex, row)} checked={selectedUserIndices.includes(rindex)} />
                                                </td>}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    ))}
                </div>
            </div >
        );
    };

    const search = () => {
        if (!currTeamHash) {
            alert("Please select a team");
            return;
        }
        loadTeamMembers(currTeamHash);
        setReloadResults(true);
    };

    return (teams.length === 0 ?
        <h2>Team profile is needed to analyze the teams
            <br />Follow the instructions in our <a href='https://github.com/modelteam-ai/modelteam.ai/blob/main/README_org.md#getting-started' target='_blank' rel="noreferrer">Git README</a> to build one.</h2>
        :
        <div className='team-prof'>
            <div className='skill-search-header'>
                <div>
                    <label>Team</label>
                    <select onChange={handleTeamSelectChange} value={selectedTeamIndex}>
                        <option value="">Select a Team</option>
                        {teams.map((item, index) => (
                            <option key={index} value={index}>
                                {item.team_name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Languages (Max 2)</label>
                    <MultiSelect items={teamLangs} maxSel={2} onSelectChange={handleLangSelect} defItems={selectedLangs} itemKey={'_key'} />
                </div>
                <div>
                    <label>Skills (Max 5)</label>
                    <MultiSelect items={teamSkills} maxSel={5} onSelectChange={handleSkillSelect} defItems={selectedSkills} itemKey={'_key'} />
                </div>
                {(!compareFlag && !singleProfile) ?
                    <button onClick={search} disabled={selectedLangs.length === 0 && selectedSkills.length === 0}>Search</button>
                    :
                    <button onClick={() => {
                        setCompareFlag(false);
                        setSingleProfile(null);
                    }} className='small_button'>Back</button>}
            </div>
            {displayResults(filteredResults)}
        </div>
    );
}

export default OrgSkillSearch;