// Layout.jsx

import { default as React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/profile.css';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import Background from './Background';
import Footer from './Footer';
import Header from './Header';
import ProgressTracker from './ProgressTracker';

const EngLayout = ({ children, isLoggedIn, reloadStatus = false }) => {
  const currPage = window.location.pathname;
  const navigate = useNavigate();
  const [status, setStatus] = useState(localStorage.getItem('status') || 0);
  const userApiPrefix = '/api/v1/user/status';
  const steps = [
    { name: "About Me", path: "/account" },
    { name: "Projects", path: "/experience" },
    { name: "Profile", path: "/profile" },
    { name: "Job Applications", path: "/myjobs" }
  ];


  function goto(path) {
    if (!isLoggedIn) {
      return;
    }
    // binary 11
    if (path === '/profile' && status < 3) {
      return;
    }
    if (path === '/myjobs' && status < 7) {
      return;
    }
    navigate(path);
  }

  function getIndexOfPath(path) {
    path = path.split("?")[0];
    if (path === "/") {
      return 0;
    }
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].path === path) {
        return i;
      }
    }
    return 0;
  }

  useEffect(() => {
    if (isLoggedIn) {
      getStatus();
    }
  }, [navigate, isLoggedIn]);

  useEffect(() => {
    if (reloadStatus) {
      getStatus();
    }
  }, [reloadStatus]);

  async function getStatus() {
    const response = await fetchAPIWithToken(navigate, `${userApiPrefix}`, 'GET', {});
    if (response.status === 200) {
      const data = await response.json();
      //status 0000 - no user, 0001 user exists, 0011 user exists with experience, 
      // 1111 user exists with experience and profile + job applications are optional
      localStorage.setItem('status', data.status);
      setStatus(data.status);
    }
  }


  return (
    <div className="content">
      <Header />
      <Background />
      <ProgressTracker currPage={currPage} goto={goto} status={status} steps={steps} getIndexOf={getIndexOfPath} />
      <main>
        {children}
      </main>
      <Footer />
    </div >
  );
}

export default EngLayout;
