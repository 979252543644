import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/welcome.css';
import '../styles/root.css';
import logo from '../assets/modelteam_logo.png';
import Layout from '../components/Layout';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Welcome = () => {

    const navigate = useNavigate();
    const query = useQuery();

    const referrer = query.get('ref') || '';
    const applyJob = query.get('applyto') || '';
    const atsCandidate = query.get('atsid') || '';
    const jobTitle = query.get('jobtitle') || '';

    const redirectUrl = '/login?ref=' + referrer + '&applyto=' + applyJob + '&atsid=' + atsCandidate;
    
    return (
        <div className='welcome-container'>
            <div className='welcome-content'>
                <div className='welcome-title'><img src={logo} alt="modelteam.ai" className='small-logo' /></div>
                <div className='welcome-big-text'>Welcome</div>
                <div className='welcome-subtitle'>Scale your peaks. Grow your skills. Get started with us today</div>
                <div className='welcome-buttons'>Login</div>
            </div>
            <div className='welcome-graphic'> </div>
        </div>
    );
};

export default Welcome;
