import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Account from './pages/Account';
import CreateAccount from './pages/CreateAccount';
import Experience from './pages/Experience';
import Logout from './pages/Logout';
import MyJobs from './pages/MyJobs';
import OrgAccount from './pages/OrgAccount';
import OrgHiringCoPilot from './pages/OrgHiringCoPilot';
import OrgJobs from './pages/OrgJobs';
import OrgLogin from './pages/OrgLoginPage';
import OrgLogout from './pages/OrgLogout';
import OrgTeamProf from './pages/OrgTeamProf';
import OrgTeams from './pages/OrgTeams';
import ProfilePage from './pages/ProfilePage';
import ProfileSummary from './pages/ProfileSummary';
import Refer from './pages/Refer';
import UserLogin from './pages/UserLogInPage';
import Welcome from './pages/Welcome';
import { fetchAPI } from './utils/ApiUtils';
import OrgAnalyzeTeams from './pages/OrgAnalyzeTeams';

function App() {
    useEffect(() => {
        const pingApiServer = async () => {
            try {
                const response = await fetchAPI('/ping', 'GET');
                if (response.ok) {
                    console.log('Ping successful');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        pingApiServer();
    }, []);


    return (
        <Router>
            <Routes>
                <Route path="/" element={<Account />} />
                <Route path="/signup" element={<UserLogin />} />
                <Route path="/login" element={<UserLogin />} />
                <Route path='/refer' element={<Refer />} />
                <Route path="/account" element={<Account />} />
                <Route path="/myjobs" element={<MyJobs />} />
                <Route path="/experience" element={<Experience />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/summary" element={<ProfileSummary />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/create" element={<CreateAccount />} />
                <Route path='/org/' element={<OrgAccount />} />
                <Route path='/org/signup' element={<OrgLogin />} />
                <Route path='/org/login' element={<OrgLogin />} />
                <Route path='/org/account' element={<OrgAccount />} />
                <Route path='/org/teams' element={<OrgTeams />} />
                <Route path='/org/copilot' element={<OrgHiringCoPilot />} />
                <Route path='/org/teamprof' element={<OrgAnalyzeTeams />} />
                <Route path='/org/jobs' element={<OrgJobs />} />
                <Route path='/org/logout' element={<OrgLogout />} />
                <Route path="*" element={<h1>Not Found</h1>} />
            </Routes>
        </Router>
    );
}

export default App;
