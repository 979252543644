import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAPIWithToken } from '../utils/ApiUtils';

const Logout = () => {
    const navigate = useNavigate();
    const api = '/api/v1/auth/logout';

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
        fetchAPIWithToken(navigate, api, 'POST', {});
        navigate('/login?redirect=/account');
    }, [navigate]);

    return null;
};

export default Logout;
