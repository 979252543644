// src/Account.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Geo from '../components/Geo';
import OrgAdminView from '../components/OrgAdminView';
import OrgDashboard from '../components/OrgDashboard';
import OrgLayout from '../components/OrgLayout';
import Tab from '../components/Tab';
import TabGroup from '../components/TabGroup';
import '../styles/account.css';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { isOrgTokenExpired, ORG_PREFIX, saveOrgToken } from '../utils/CheckToken';


const userApiPrefix = '/api/v1/org/user/account';
const OrgAccount = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [emailHash, setEmailHash] = useState('');
    const [whoAmI, setWhoAmI] = useState({
        email_hash: '',
        org_name: '',
        website: '',
        contact_name: '',
        email: '',
        city: '',
        state: '',
        country: '',
        updated_at: '',
        referrer_id: '',
        ignore_domain_in_user: false
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isAdminView, setIsAdminView] = useState(false);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setWhoAmI(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const setGeo = (geo) => {
        setWhoAmI(prevState => ({
            ...prevState,
            ...geo
        }));
    };

    useEffect(() => {
        // New users will not have email_hash in local storage
        setIsLoggedIn(!isOrgTokenExpired(false));
        setIsLoginChecked(true);
        const localEmail = localStorage.getItem(ORG_PREFIX + 'email');
        const emailHash = localStorage.getItem(ORG_PREFIX + 'email_hash');
        setEmailHash(emailHash);

        if (emailHash) {
            loadWhoAmI().then(() => {
                setIsEditing(false);
            });
        } else {
            whoAmI['email'] = localEmail;
            const ref = localStorage.getItem(ORG_PREFIX + 'ref');
            whoAmI['referrer_id'] = ref ? ref : '';
            setIsEditing(true);
        }
    }, [navigate]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/org/login?redirect=/org/account`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    const loadWhoAmI = async () => {
        const response = await fetchAPIWithToken(navigate, `${userApiPrefix}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            setEmailHash('');
            return;
        }
        const data = await response.json();
        setWhoAmI(data);
    };

    const setIgnoreDomain = (event) => {
        const { name, checked } = event.target;
        setWhoAmI(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    function validateUserAccount(whoAmI) {
        if (!whoAmI.org_name || !whoAmI.website || !whoAmI.contact_name || !whoAmI.country) {
            alert('Please fill in all required fields');
            return false;
        }
        if (!emailHash && !document.getElementById('tos').checked) {
            alert('Please agree to the terms of service and privacy statement');
            return false;
        }
        return true;
    };

    const createOrUpdateAccount = async () => {
        if (!validateUserAccount(whoAmI)) {
            return;
        }
        if (emailHash) {
            const response = await fetchAPIWithToken(navigate, userApiPrefix, 'PUT', whoAmI);
            if (response.status === 200) {
                const data = await response.json();
                setWhoAmI(data);
                setIsEditing(false);
            }
            else {
                console.error(response);
            }
        } else {
            const response = await fetchAPIWithToken(navigate, userApiPrefix, 'POST', whoAmI);
            if (response.status === 201) {
                const data = await response.json();
                // Logged in user will have new token issued once the account is created
                saveOrgToken(data);
                setEmailHash(data.email_hash);
                setWhoAmI(data);
                setIsEditing(false);
                navigate('/org/teams');
            } else {
                const resp = await response.json();
                alert('Error creating account - ' + resp.error);
                console.error(response);
            }
        }
    };

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    return (
        <OrgLayout isLoggedIn={emailHash && isLoggedIn} reloadStatus={false}>
            <div className='outer-box'>
                <div className='right-pane'>
                    <div className='flex_row'>
                        <h3>Hello {whoAmI.org_name ? whoAmI.org_name : `${whoAmI.email}, Please create your account`}!</h3>
                        {!isEditing && whoAmI.is_org_admin && !isAdminView && <button className='less_used_button' id="edit" onClick={() => setIsEditing(true)}>Edit Details</button>}
                    </div>
                    {(!isEditing && whoAmI.is_org_admin) &&
                        <TabGroup>
                            <Tab name="Dashboard" isActive={!isAdminView} onClick={() => setIsAdminView(false)} />
                            <Tab name="Manage Users" isActive={isAdminView} onClick={() => setIsAdminView(true)} />
                        </TabGroup>
                    }

                    {isEditing ?
                        <div className='whoami'>
                            <div className='two_columns'>
                                <div className='whoami'>
                                    <label htmlFor="org_name">Org Name*</label>
                                    <input type="text" id="org_name" name="org_name" required readOnly={!isEditing} value={whoAmI.org_name} onChange={handleChange} />
                                </div>
                                <div className='whoami'>
                                    <label htmlFor="website">Website*</label>
                                    <input type="text" id="website" name="website" required readOnly={!isEditing} value={whoAmI.website} onChange={handleChange} />
                                </div>
                            </div>
                            {/* contact details can be changed from acl window only */}
                            {!emailHash &&
                                <div className='two_columns'>
                                    <div className='whoami'>
                                        <label htmlFor="email">Contact Email*</label>
                                        <input type="text" id="email" name="email" required readOnly={true} value={whoAmI.email} onChange={handleChange} />
                                    </div>
                                    <div className='whoami'>
                                        <label htmlFor="contact_name">Contact Name*</label>
                                        <input type="text" id="contact_name" name="contact_name" required readOnly={!isEditing} value={whoAmI.contact_name} onChange={handleChange} />
                                    </div>
                                </div>}
                            <Geo setGeo={setGeo} isEditing={isEditing} currGeo={whoAmI} />
                            {!emailHash &&
                                <label className='tos'>
                                    <input type="checkbox" id="tos" name="tos" required readOnly={!isEditing} title='Terms & Conditions' />
                                    &nbsp;By creating an account, you agree to our <a href='https://modelteam.ai/#privacy' target='_blank' rel='noreferrer'>Privacy Statement</a> and <a href='https://modelteam.ai/#terms' target='_blank' rel='noreferrer'>Terms of Service</a>
                                </label>
                            }
                            {emailHash &&
                                <div className='whoami'>
                                    <b>Advanced Settings</b>
                                    <label className='tos'>
                                        <input type="checkbox" id="igd" name="ignore_domain_in_user" required readOnly={!isEditing} title='Ignore Domain' checked={whoAmI.ignore_domain_in_user} onChange={setIgnoreDomain} />
                                        &nbsp;Ignore Domain in git email ids.
                                    </label>
                                    <label> E.g. stats for john@acme.inc and john@acme.com will be combined to single user john</label>
                                    <label> IMPORTANT: Change will be applied only when the team profiles are updated.</label>
                                </div>
                            }
                            <div className='col_buttons'>
                                {isEditing && emailHash &&
                                    <button id="cancel" onClick={() => {
                                        setIsEditing(false);
                                        loadWhoAmI();
                                    }}>Cancel</button>
                                }
                                {isEditing &&
                                    <button onClick={createOrUpdateAccount}>Submit</button>
                                }
                            </div>
                        </div>
                        :
                        (isAdminView ? <OrgAdminView /> : <OrgDashboard />)
                    }
                </div>
            </div>
        </OrgLayout>
    );
};

export default OrgAccount;
