import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OrgLayout from '../components/OrgLayout';
import Tab from '../components/Tab';
import TabGroup from '../components/TabGroup';
import { isOrgTokenExpired } from '../utils/CheckToken';
import OrgSkillSearch from './OrgSkillSearch';
import OrgTeamProf from './OrgTeamProf';



const OrgAnalyzeTeams = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [activeTab, setActiveTab] = useState('User / Cohort');

    useEffect(() => {
        setIsLoggedIn(!isOrgTokenExpired());
        setIsLoginChecked(true);
    }, []);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/org/login?redirect=/org/teamprof`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    const TabContent = ({ activeTab }) => {
        if (activeTab === 'User / Cohort') {
            return <OrgTeamProf />;
        } else {
            return <OrgSkillSearch />;
        }
    };

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    return (
        <OrgLayout isLoggedIn={isLoggedIn} reloadStatus={false}>
            <div className="profile-tabs-left">
                <TabGroup>
                    <Tab name="User / Cohort" isActive={activeTab === 'User / Cohort'} onClick={() => setActiveTab('User / Cohort')} />
                    <Tab name="Search by Skills" isActive={activeTab === 'Search by Skills'} onClick={() => setActiveTab('Search by Skills')} />
                </TabGroup>
            </div>
            <TabContent activeTab={activeTab} />
        </OrgLayout>
    );
}

export default OrgAnalyzeTeams;