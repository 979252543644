import axios from 'axios';
import { useEffect, useState } from 'react';
import Select from 'react-select';

import '../styles/root.css';
import { selectStyles } from './SelectStyles';

const geoCache = {};

const Geo = ({ currGeo, setGeo, isEditing }) => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [city, setCity] = useState(currGeo.city || '');
    const [state, setState] = useState(currGeo.state || '');
    const [country, setCountry] = useState(currGeo.country || '');

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        if (currGeo) {
            setCountry(currGeo.country);
            setState(currGeo.state);
            setCity(currGeo.city);
        }
    }, [currGeo]);

    useEffect(() => {
        if (country && countries.find(c => c.value === country)) {
            setStates([]);
            setCities([]);
            fetchStates(country)
        }
    }, [country, countries]);

    useEffect(() => {
        if (state && states.find(s => s.value === state)) {
            setCities([]);
            fetchCities(country, state);
        }
    }, [state, states]);

    useEffect(() => {
        if (!country) {
            return;
        }
        if (country === currGeo.country && state === currGeo.state && city === currGeo.city) {
            return;
        }
        if (countries.find(c => c.value === country) &&
            (!state || states.find(s => s.value === state)) &&
            (!city || cities.find(c => c.value === city))) {
            setGeo({
                'country': country,
                'state': state,
                'city': city
            });
        }
    }, [country, state, city]);


    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption ? selectedOption.value : '');
    };

    const handleStateChange = (selectedOption) => {
        setState(selectedOption ? selectedOption.value : '');
    };

    const handleCityChange = (selectedOption) => {
        setCity(selectedOption ? selectedOption.value : '');
    };

    const fetchCountries = async () => {
        try {
            const response = await axios.get('/assets/countries/countries.json');
            const countryList = Object.keys(response.data).map(countryName => ({
                value: response.data[countryName],
                label: countryName
            }));
            setCountries(countryList);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchStates = async (country) => {
        try {
            const validCountry = countries.find(c => c.value === country);
            if (!validCountry) {
                console.log('Invalid country:', country);
                return;
            }
            if (!geoCache[country]) {
                const response = await axios.get(`/assets/countries/${validCountry.value}.json`);
                geoCache[country] = response.data;
            }
            const stateList = Object.keys(geoCache[country]).map(stateName => ({
                value: stateName,
                label: stateName
            }));
            setStates(stateList);
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    const fetchCities = async (country, state) => {
        try {
            const validCountry = countries.find(c => c.value === country);
            if (!validCountry) {
                console.log('Invalid country:', country);
                return;
            }
            const validState = states.find(s => s.value === state);
            if (!validState) {
                console.log('Invalid state:', state);
                return;
            }
            if (!geoCache[country]) {
                const response = await axios.get(`/assets/countries/${validCountry.value}.json`);
                geoCache[country] = response.data;
            }
            const cityList = geoCache[country][validState.value].map(city => ({
                value: city,
                label: city
            }));
            setCities(cityList);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    return isEditing ? <div className='whoami'>
        <label>Country *</label>
        <Select
            id="country"
            name="country"
            value={countries.find(c => c.value === country) || country || currGeo.country}
            onChange={handleCountryChange}
            options={countries}
            isDisabled={!isEditing}
            styles={selectStyles}
        />
        <label>State</label>
        <Select
            id="state"
            name="state"
            value={states.find(s => s.value === state) || state || currGeo.state}
            onChange={handleStateChange}
            options={states}
            isDisabled={!isEditing || !states.length}
            styles={selectStyles}
        />
        <label>City</label>
        <Select
            id="city"
            name="city"
            value={cities.find(c => c.value === city) || city || currGeo.city}
            onChange={handleCityChange}
            options={cities}
            isDisabled={!isEditing || !cities.length}
            styles={selectStyles}
        />
    </div> :
        <div className='whoami'>
            <label>City, State, Country</label>
            <input type="text" id="city" name="city" required readOnly={!isEditing} value={`${city ? city : '-'}, ${state ? state : '-'}, ${country}`} />
        </div>
};

export default Geo;
