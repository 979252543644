export const selectStyles = {
    control: (provided) => ({
        ...provided,
        fontFamily: 'var(--font-family)',
        fontSize: 'medium',
        width: '97%',
        marginBottom: '10px',
        backgroundColor: 'transparent',
        color: 'var(--text-primary)',
        textAlign: 'left',
        borderRadius: 'var(--radius-sm)',
        border: '1px solid var(--border-brand)',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--text-primary)',
        backgroundColor: 'transparent',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'var(--text-primary)',
        backgroundColor: 'transparent',
    }),
    option: (provided, state) => ({
        ...provided,
        textAlign: 'left',
        // color: 'var(--text-primary)',
        color: state.isFocused ? 'black' : 'var(--text-secondary)',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
        border: '1px solid var(--border-brand)',
        borderRadius: 'var(--radius-sm)',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'var(--text-primary)',
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'var(--bg-primary)',
        color: 'var(--text-primary)',
    }),
    input: (provided) => ({
        ...provided,
        color: 'var(--text-primary)', // Set the text color to white or your preferred color
        backgroundColor: 'transparent', // Ensure background remains transparent
    })
};
