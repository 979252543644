// src/Jobs.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EngLayout from '../components/EngLayout';
import '../styles/account.css';
import '../styles/jobs.css';
import '../styles/root.css';
import { fetchAPIWithToken } from '../utils/ApiUtils';
import { isTokenExpired } from '../utils/CheckToken';
import { formatDate } from '../utils/DateTimeUtils';

const jobsApiPrefix = '/api/v1/work/jobs';
const jobApiPrefix = '/api/v1/work/job';
const skillApiPrefix = '/api/v1/work/skills'

const Experience = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [workEmailHash, setWorkEmailHash] = useState('');
    const [fileName, setFileName] = useState('');
    const [userEmailHash, setUserEmailHash] = useState(localStorage.getItem('email_hash'));
    const [jobs, setJobs] = useState([]);
    const [selectedJobIndex, setSelectedJobIndex] = useState(-1);
    const [addNew, setAddNew] = useState(false);
    const [reloadJobs, setReloadJobs] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [showDeletedJobs, setShowDeletedJobs] = useState(false);

    const handleCheckboxChange = (event) => {
        setShowDeletedJobs(event.target.checked);
    };

    const [currJob, setCurrJob] = useState({
        user_email_hash: '',
        work_email_hash: '',
        work_email: '',
        current_company: '',
        deleted: false,
        skill_profile_status: 0
    });

    const [skillProfiles, setSkillProfiles] = useState([]);
    const [selectedSkillProfile, setSelectedSkillProfile] = useState('');
    const [currSkillProfile, setCurrSkillProfile] = useState({
        id: 0,
        name: '',
        repo_list: '',
        created_at: '',
        deleted: false,
        display_name: ''
    });

    const resetSkillState = () => {
        setSkillProfiles([]);
        setSelectedSkillProfile('');
        setCurrSkillProfile({
            id: 0,
            name: '',
            repo_list: '',
            created_at: '',
            deleted: false,
            display_name: ''
        });
        const profile = document.getElementById('profile')
        if (profile) {
            profile.value = '';
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCurrJob(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    function pickCurrJob(jobs) {
        let noJob = false;
        if (!workEmailHash || (currJob.deleted && !showDeletedJobs)) {
            noJob = true;
            for (let i = 0; i < jobs.length; i++) {
                if (!jobs[i].deleted || showDeletedJobs) {
                    setSelectedJobIndex(i);
                    setCurrJob(jobs[i]);
                    setWorkEmailHash(jobs[i].work_email_hash);
                    noJob = false;
                    setAddNew(false);
                    break;
                }
            }
        }
        if (noJob) {
            setCurrJob({
                user_email_hash: userEmailHash,
                work_email_hash: '',
                work_email: '',
                current_company: '',
                deleted: false,
                skill_profile_status: 0
            });
            setAddNew(true);
            setSelectedJobIndex(-1);
            setWorkEmailHash('');
        }
    }


    useEffect(() => {
        setIsLoggedIn(!isTokenExpired());
        setIsLoginChecked(true);
    }, []);

    useEffect(() => {
        pickCurrJob(jobs);
    }, [showDeletedJobs]);

    useEffect(() => {
        pickCurrJob(jobs);
    }, [jobs]);

    useEffect(() => {
        loadJobs();
        loadSkillProfiles();
        currJob['user_email_hash'] = userEmailHash;
    }, [navigate]);

    useEffect(() => {
        if (reloadJobs) {
            loadJobs();
            loadSkillProfiles();
            currJob['user_email_hash'] = userEmailHash;
            setReloadJobs(false);
        }
    }, [reloadJobs]);

    useEffect(() => {
        resetSkillState();
        loadJob();
        loadSkillProfiles();
        setFileName('');
        setFileSelected(false);
    }, [workEmailHash]);

    useEffect(() => {
        if (isLoginChecked && !isLoggedIn) {
            navigate(`/login?redirect=/experience`);
        }
    }, [isLoginChecked, isLoggedIn, navigate]);

    const loadJob = async () => {
        if (workEmailHash) {
            const response = await fetchAPIWithToken(navigate, `${jobApiPrefix}?work_email_hash=${workEmailHash}`, 'GET', {});
            if (response.status !== 200) {
                console.error(response);
                return;
            }
            const data = await response.json();
            setCurrJob(data);
        } else {
            setCurrJob({
                user_email_hash: userEmailHash,
                work_email_hash: '',
                work_email: '',
                current_company: '',
                deleted: false,
                skill_profile_status: 0
            });
        }
    }

    const loadJobs = async () => {
        if (!userEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${jobsApiPrefix}?user_email_hash=${userEmailHash}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        if (data.length > 0) {
            setJobs(data);
        }
    };

    const loadSkillProfiles = async () => {
        if (!workEmailHash || !userEmailHash) {
            return;
        }
        const response = await fetchAPIWithToken(navigate, `${skillApiPrefix}?work_email_hash=${workEmailHash}`, 'GET', {});
        if (response.status !== 200) {
            console.error(response);
            return;
        }
        const data = await response.json();
        setSkillProfiles(data);
        if (data.length > 0) {
            setSelectedSkillProfile(data[0].id);
            setCurrSkillProfile(data[0]);
        }
        const profile = document.getElementById('profile')
        if (profile) {
            profile.value = '';
        }
    };

    function validateJob(fileInput) {
        if (!currJob.current_company) {
            alert('Please provide the company/school name.');
            return false;
        }
        if (!fileInput || fileInput.files.length === 0) {
            alert('Please select the file to upload.');
            return false;
        }
        return true;
    }
    const createOrUpdateJob = async () => {
        if (workEmailHash) {
            const response = await fetchAPIWithToken(navigate, jobApiPrefix, 'PUT', currJob);
            if (response.status === 200) {
                const data = await response.json();
                setCurrJob(data);
                setIsEditing(false);
                setReloadJobs(true);
            }
            else {
                console.error(response);
            }
        }
    };

    const toggleDelete = async () => {
        if (workEmailHash) {
            if (!currJob.deleted) {
                if (!window.confirm('Are you sure you want to delete this experience?')) {
                    return;
                }
            }
            currJob.deleted = !currJob.deleted;
            await createOrUpdateJob();
        }
    }

    const toggleDeleteSkillProfile = async () => {
        if (selectedSkillProfile) {
            if (!currSkillProfile.deleted) {
                if (!window.confirm('Are you sure you want to delete this file from your profile?')) {
                    return;
                }
            }
            currSkillProfile.deleted = !currSkillProfile.deleted;
            await deleteSkillProfile();
            await loadSkillProfiles();
        }
    }

    const deleteSkillProfile = async () => {
        const response = await fetchAPIWithToken(navigate, skillApiPrefix, 'PUT', currSkillProfile);
        if (response.status === 200) {
            const data = await response.json();
            setSelectedSkillProfile(data.id);
            setCurrSkillProfile(data);
        }
        else {
            console.error(response);
        }
    }

    const uploadSkillFile = async () => {
        const fileInput = document.getElementById('profile');
        if (validateJob(fileInput)) {
            const fileName = fileInput.files[0].name;
            // TODO: remove mt_profile_ after migration
            if (fileName.endsWith('.json.gz') && (fileName.startsWith('mt_stats_') || fileName.startsWith('mt_profile_'))) {
                const formData = new FormData();
                formData.append('file', fileInput.files[0]);
                if (workEmailHash) {
                    formData.append('work_email_hash', workEmailHash);
                }
                formData.append('current_company', currJob.current_company);
                try {
                    const response = await fetchAPIWithToken(navigate, skillApiPrefix, 'POST', formData, true, true);
                    if (response.status !== 201) {
                        const data = await response.json();
                        console.error(data);
                        alert('Error uploading file: ' + data.error);
                        return;
                    }
                    if (!workEmailHash) {
                        const data = await response.json();
                        setWorkEmailHash(data.work_email_hash);
                        setIsEditing(false);
                        setAddNew(false);
                        setReloadJobs(true);
                    }
                    loadSkillProfiles();
                    alert('Success! Your user profile will be updated shortly in "Profile" section.');
                    fileInput.value = '';
                    setFileSelected(false);
                    navigate('/profile');
                } catch (error) {
                    console.log(error);
                    alert('Error uploading file');
                }
            } else {
                console.log('Invalid file format');
                alert('Invalid file format');
                fileInput.value = '';
                setFileSelected(false);
            }
        }
    }

    const handleSkillChange = (event) => {
        const { value } = event.target;
        setSelectedSkillProfile(value);
        const profile = skillProfiles.find(profile => profile.id === parseInt(value));
        setCurrSkillProfile(profile);
    }

    const displaySkillProfile = () => {
        if (skillProfiles.length > 0) {
            return <div>
                <div className='two_columns'>
                    <div className='whoami'>
                        <select name='skill_profiles' id='skill_profiles' size={5} onChange={handleSkillChange} value={selectedSkillProfile}>
                            {skillProfiles.map((skillProfile, index) => (
                                <option key={index} value={skillProfile.id}>{skillProfile.display_name + (skillProfile.deleted ? ' 🗑' : '')}</option>
                            ))}
                        </select>
                    </div>
                    {selectedSkillProfile &&
                        <div className='whoami'>
                            <label>{'Created on: ' + formatDate(currSkillProfile.created_at)}</label>
                            <label>{'Repo List: ' + currSkillProfile.repo_list}</label>
                            <label>{'Status: ' + (currSkillProfile.deleted ? 'Deleted' : 'Active')}</label>
                            <button className={currSkillProfile.deleted ? '' : 'red'} onClick={toggleDeleteSkillProfile}>{currSkillProfile.deleted ? 'Add File Back' : 'Delete File'}</button>
                        </div>
                    }
                </div>
            </div>
        }
    }

    const displayJob = () => {
        if (addNew || workEmailHash) {
            const readOnly = addNew ? false : !isEditing;
            return <div className='right-pane-jobs'>
                <div className='whoami'>
                    {addNew && <div>
                        <h2>Your Code &gt;&gt; Your Profile</h2>
                        {!fileSelected && <p>
                            <b>What to expect:</b><br />
                            0. Run on the computer that has your code<br />
                            1. Set up modelteam. (~5 mins)<br />
                            2. Analyze your code to build skill profile. (1 min to analyze 1000 lines of code. ~15 mins)<br />
                            3. Edit and upload (return here to upload). (~5 mins)<br />
                        </p>}
                        <Link to='https://github.com/modelteam-ai/modelteam.ai/blob/main/README.md#getting-started' target='_blank'><button className='button-cta'>{skillProfiles.length > 0 ? 'Update' : 'Build'} your Skill Profile ✨</button></Link>
                        <br />
                        <br />
                    </div>}
                    <label htmlFor='current_company'>Company/School (Can't Change Once Created)*</label>
                    <div className='two_columns'>
                        <input type='text' id='current_company' name='current_company' placeholder='Company/School' readOnly={readOnly} value={currJob.current_company} onChange={handleChange} />
                        {(!addNew && readOnly) && <button className={currJob.deleted ? '' : 'red'} onClick={toggleDelete}>{currJob.deleted ? 'Add Back' : 'Delete'}</button>}
                    </div>
                    {readOnly &&
                        <>
                            <label htmlFor='work_email'>Git Email Id</label>
                            <input type='email' id='work_email' name='work_email' placeholder='Email Id in the git commit logs' value={currJob.work_email} readOnly={true} />
                        </>
                    }
                    <div className='file_upload'>
                        <label><b>Files</b></label>
                        {displaySkillProfile()}
                        <div className='whoami'>
                            <label htmlFor="profile" className="small_button">
                                <b>Upload Skill Profile</b><br /><h5 className='no_margin_h2'>( mt_stats_****.json.gz )</h5>
                            </label>
                            {!currJob.deleted && <input
                                id="profile"
                                type="file"
                                name="profile"
                                accept=".gz"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                            />}
                            {fileSelected && <label>{fileName}</label>}
                        </div>
                    </div>
                    {fileSelected && !currJob.deleted && !readOnly && <div className='col_buttons'>
                        <button id="cancel" onClick={() => {
                            if (addNew) {
                                setAddNew(false);
                                if (jobs.length === 0) {
                                    navigate('/account');
                                } else if (!workEmailHash) {
                                    setSelectedJobIndex(0);
                                    setWorkEmailHash(jobs[0].work_email_hash);
                                }
                            }
                            setIsEditing(false);
                            loadJob();
                        }}>Cancel</button>
                        <button id="submit" onClick={uploadSkillFile}>Submit</button>
                    </div>}
                </div>
            </div >
        } else {
            return <div className='right-pane-jobs'></div>
        }
    }

    const handleJobClick = (index, workEmailHash) => {
        setSelectedJobIndex(index);
        setWorkEmailHash(workEmailHash)
        setAddNew(false);
    }

    const handleAddNew = () => {
        setSelectedJobIndex(-1);
        setWorkEmailHash('');
        setAddNew(true);
    }

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setFileName(event.target.files[0].name);
            setFileSelected(true);
            if (!addNew && !currJob.deleted) {
                uploadSkillFile();
            }
        } else {
            setFileName('');
            setFileSelected(false);
        }
    };

    if (!isLoginChecked) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        return <div>Not logged in</div>;
    }

    return (
        <EngLayout isLoggedIn={isLoggedIn} reloadStatus={reloadJobs}>
            <div className='outer-box-top'>
                <div className='left-pane'>
                    {jobs.length > 0 && <label>
                        <input
                            type="checkbox"
                            checked={showDeletedJobs}
                            onChange={handleCheckboxChange}
                        />
                        Show Deleted Experiences
                    </label>}
                    {jobs.map((job, index) => (
                        (showDeletedJobs || !job.deleted) ? (
                            <div name={index + "-" + selectedJobIndex} key={index} className={`job_div ${selectedJobIndex === index ? 'selected' : ''}`} onClick={() => handleJobClick(index, job.work_email_hash)}>
                                <div>
                                    <b>{job.work_email}</b>{job.deleted ? ' 🗑' : ''}
                                    <br />
                                    {job.current_company}
                                </div>
                            </div>
                        ) : null
                    ))}
                    {jobs.length > 0 && <div onClick={() => handleAddNew()} className={`job_div ${addNew ? 'selected' : ''}`}>
                        <h4>Add New +</h4>
                    </div>}
                </div>
                {displayJob()}
            </div>
        </EngLayout>
    );
};

export default Experience;
